import request from '@/utils/request'

// 查询车辆信息列表
export function listcar_info(query) {
  return request({
    url: '/car/car_info/list',
    method: 'get',
    params: query
  })
}

// 查询车辆信息详细
export function getcar_info(id) {
  return request({
    url: '/car/car_info/' + id,
    method: 'get'
  })
}

// 新增车辆信息
export function addcar_info(data) {
  return request({
    url: '/car/car_info',
    method: 'post',
    data: data
  })
}

// 修改车辆信息
export function updatecar_info(data) {
  return request({
    url: '/car/car_info',
    method: 'put',
    data: data
  })
}

// 修改车辆信息
export function updateCarInfoStatus(data) {
  return request({
    url: '/car/car_info/editStatus',
    method: 'post',
    data: data
  })
}

export function updateCarInfoSort(data) {
  return request({
    url: '/car/car_info/editSort',
    method: 'post',
    data: data
  })
}


// 删除车辆信息
export function delcar_info(id) {
  return request({
    url: '/car/car_info/' + id,
    method: 'delete'
  })
}

// 查询所有车辆信息列表
export function listAllcar_info(query) {
  return request({
    url: '/car/car_info/list-all',
    method: 'get',
    params: query
  })
}

// 查询车辆数量
export function carCount() {
  return request({
    url: '/car/car_info/carCount',
    method: 'get'
  })
}
