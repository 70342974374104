import request from '@/utils/request'

// 查询组合产品列表
export function getPlayDays(query) {
  return request({
    url: '/combinationProducts/combination-products/getPlayDays',
    method: 'get',
    params: query
  })
}

// 查询组合产品列表
export function listcombinationProducts(query) {
  return request({
    url: '/combinationProducts/combination-products/list',
    method: 'get',
    params: query
  })
}

// 查询组合产品详细
export function getcombinationProducts(id) {
  return request({
    url: '/combinationProducts/combination-products/' + id,
    method: 'get'
  })
}

// 新增组合产品
export function addcombinationProducts(data) {
  return request({
    url: '/combinationProducts/combination-products',
    method: 'post',
    data: data
  })
}

// 修改组合产品
export function updatecombinationProducts(data) {
  return request({
    url: '/combinationProducts/combination-products',
    method: 'put',
    data: data
  })
}

// 删除组合产品
export function delcombinationProducts(id) {
  return request({
    url: '/combinationProducts/combination-products/' + id,
    method: 'delete'
  })
}

// 查询所有组合产品列表
export function listAllcombinationProducts(query) {
  return request({
    url: '/combinationProducts/combination-products/list-all',
    method: 'get',
    params: query
  })
}

// 根据有效日期查询所有组合产品列表
export function listAllRouteProducts(query) {
  return request({
    url: '/combinationProducts/route-product-api/list-all',
    method: 'get',
    params: query
  })
}
// 根据有效日期查询所有组合产品列表及价格信息
export function listAllRouteProductsAndPrice(query) {
  return request({
    url: '/combinationProducts/route-product-api/combination-products',
    method: 'get',
    params: query
  })
}

// 添加分销配置
export function addDistribution(row){
  return request({
    url: '/combinationProducts/addDistribution',
    method: 'post',
    data: row
  })
}

// 组合产品数量（统计）
export function comProCount(){
  return request({
    url: '/combinationProducts/combination-products/comProCount',
    method: 'get'
  })
}
