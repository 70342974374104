import request from '@/utils/request'

// 查询团队申报订单主列表(景区使用查看所有的)
export function travelAllorderList(query) {
  return request({
    url: '/order/order-travel-order/travel-all-list',
    method: 'get',
    params: query
  })
}
// 查询团队申报订单主列表
export function listorder(query) {
  return request({
    url: '/order/order-travel-order/list',
    method: 'get',
    params: query
  })
}

// 查询团队申报订单主详细
export function getTravelorder(travelOrderId) {
  return request({
    url: '/order/order-travel-order/' + travelOrderId,
    method: 'get'
  })
}

// 新增团队申报订单主
export function addtraveldeclareorder(data) {
  return request({
    url: '/order/order-travel-order',
    method: 'post',
    data: data
  })
}

// 修改团队申报订单主
export function updateorder(data) {
  return request({
    url: '/order/order-travel-order',
    method: 'put',
    data: data
  })
}

// 删除团队申报订单主
export function delorder(travelOrderId) {
  return request({
    url: '/order/order-travel-order/' + travelOrderId,
    method: 'delete'
  })
}

// 查询所有团队申报订单主列表
export function listAllorder(query) {
  return request({
    url: '/order/order-travel-order/list-all',
    method: 'get',
    params: query
  })
}
// 查询团队编号
export function generateTeavelCode(query) {
  return request({
    url: '/order/order-travel-order/generate-travel-code',
    method: 'get',
    params: query
  })
}

// 门票出票
export function travelorderoutticket(query) {
  return request({
    url: '/order/order-travel-order/travel-order-out-ticket',
    method: 'post',
    data: query
  })
}

// 门票退票
export function travelorderrefundticket(query) {
  return request({
    url: '/order/order-travel-order/travel-order-refund-ticket',
    method: 'post',
    data: query
  })
}

// 订单统计
export function orderCount(query) {
  return request({
    url: '/order/order-travel-count/orderCount?startDate='+query.startDate+'&endDate='+query.endDate,
    method: 'get'
  })
}

// 订单状态统计
export function orderStatusCount() {
  return request({
    url: '/order/order-travel-count/orderStatusCount',
    method: 'get'
  })
}


// 销售分析
export function saleAnalyse(query) {
  return request({
    url: '/order/order-travel-count/saleAnalyse?&startDate='+query.startDate+'&endDate='+query.endDate,
    method: 'get'
  })
}

// 地接客源地分析
export function areaCount() {
  return request({
    url: '/order/order-travel-count/areaCount',
    method: 'get'
  })
}

// 集团客源地分析
export function orderBlocCount(){
  return request({
    url: '/order/order-travel-count/orderBlocCount',
    method: 'get'
  })
}

// 景区管理管事顶部统计
export function orderCountMerchant(query){
  return request({
    url: '/order/order-travel-count/orderCountMerchant?startDate='+query.startDate+'&endDate='+query.endDate,
    method: 'get'
  })
}

// 景区-营销公司销售分析
export function saleAnalyseMerchant(query){
  return request({
    url: '/order/order-travel-count/saleAnalyseMerchant?startDate='+query.startDate+'&endDate='+query.endDate,
    method: 'get'
  })
}

// 景区官网顶部折线图统计
export function getFutureAndAllYearCount(){
  return request({
    url: '/order/order-travel-count/getFutureAndAllYearCount',
    method: 'get'
  })
}

// 景区官网顶部折线图统计
export function getFutureAndAllYearCountTravel(){
  return request({
    url: '/order/order-travel-count/getFutureAndAllYearCountTravel',
    method: 'get'
  })
}

// 景区-营销公司客源地分析
export function areaCountMerchant(){
  return request({
    url: '/order/order-travel-count/areaCountMerchant',
    method: 'get'
  })
}
