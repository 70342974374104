import request from '@/utils/request'

// 查询店铺信息列表
export function listShop(query) {
  return request({
    url: '/merchant/shop/list',
    method: 'get',
    params: query
  })
}

// 查询店铺信息详细
export function getShop(shopId) {
  return request({
    url: '/merchant/shop/' + shopId,
    method: 'get'
  })
}

// 新增店铺信息
export function addShop(data) {
  return request({
    url: '/merchant/shop',
    method: 'post',
    data: data
  })
}

// 修改店铺信息
export function updateShop(data) {
  return request({
    url: '/merchant/shop',
    method: 'put',
    data: data
  })
}

// 删除店铺信息
export function delShop(shopId) {
  return request({
    url: '/merchant/shop/' + shopId,
    method: 'delete'
  })
}// 冻结店铺信息
export function freezeShop(shopId, freezestatus) {
  return request({
    url: '/merchant/shop/freeze-shop/' + shopId + '/' + freezestatus,
    method: 'put'
  })
}

// 查询所有店铺信息列表
export function listAllShop(query) {
  return request({
    url: '/merchant/shop/list-all',
    method: 'get',
    params: query
  })
}

// 查询所有店铺信息列表（不含登录权限）
export function listAllShopNotPermission(query) {
  return request({
    url: '/merchant/shop/listAllNotPermission',
    method: 'get',
    params: query
  })
}

// 查询可授信店铺列表
export function listCredibleShop(distributorId) {
  return request({
    url: '/merchant/shop/list-credible-shop',
    method: 'get',
    params: {distributorId}
  })
}

// 查询当前商户拥有分销商的可授信店铺列表
export function listCredibleShopByMerchant(param) {
  return request({
    url: '/merchant/shop/list-credible-shop-by-merchant-distributorid',
    method: 'get',
    params: param
  })
}


// 查询所有店铺信息列表
export function listAllShopByType(query) {
  return request({
    url: '/merchant/shop/list-all-shop-by-type',
    method: 'get',
    params: query
  })
}

// 查询店铺数量
export function shopCount() {
  return request({
    url: '/merchant/shop/shopCount',
    method: 'get'
  })
}
