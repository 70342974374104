import request from '@/utils/request'

// 查询购物点列表
export function listinfo(query) {
  return request({
    url: '/shopping/info/list',
    method: 'get',
    params: query
  })
}

// 查询购物点详细
export function getinfo(id) {
  return request({
    url: '/shopping/info/' + id,
    method: 'get'
  })
}

// 新增购物点
export function addinfo(data) {
  return request({
    url: '/shopping/info',
    method: 'post',
    data: data
  })
}

// 修改购物点
export function updateinfo(data) {
  return request({
    url: '/shopping/info',
    method: 'put',
    data: data
  })
}

// 删除购物点
export function delinfo(id) {
  return request({
    url: '/shopping/info/' + id,
    method: 'delete'
  })
}

// 查询所有购物点列表
export function listAllinfo(query) {
  return request({
    url: '/shopping/info/list-all',
    method: 'get',
    params: query
  })
}

// 查询购物点数量
export function shoppingCount() {
  return request({
    url: '/shopping/info/shoppingCount',
    method: 'get'
  })
}
