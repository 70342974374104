<template>
  <div class="app-container" style="background-color: #F5F4F6">
    <el-row>
      <el-col :span="24">
        <el-card>
      <div style="width: 100%;float: left;" v-loading="loadingOrder">
      <el-row :gutter="15" >
        <el-col :span="6">
          <div class="titleOne xsorder">
            <div class="header-top-font">
              商户量
            </div>
            <div class=" fontsize">
              {{ countMer }}
            </div>

          </div>
        </el-col>
        <el-col :span="6" >
          <div class="titleTwo xsorder">
            <div class="header-top-font" >
              供应商量
            </div>
            <div class="fontsize">
              {{ countShop }}
            </div>

          </div>
        </el-col>
        <el-col :span="6">
          <div class="titleThree xsorder">
            <div class="header-top-font">
              订单量
            </div>
            <div class=" fontsize">
              {{ orderNum }}
            </div>

          </div>
        </el-col>
        <el-col :span="6">
          <div class="titleFour xsorder">
            <div class="header-top-font">
              核销量
            </div>
            <div class="fontsize">
              {{ checkNum }}
            </div>

          </div>
        </el-col>
      </el-row>
    </div>
          <div class="header-title">数据截止：{{dateTime}}</div>
        </el-card>
      </el-col>
    </el-row>


      <el-row>
      <el-col :span="24">
        <el-card >
          <div slot="header" class="clearfix">
            <span class="title-border">常用功能</span>
          </div>
          <div>
            <el-row :gutter="20" style="margin-left: 30px">
              <el-col :span="5">
                <img src="../assets/index_images/shop_icon.png" class="img-icon"/>
                <div class="img-icon-title">
                <a @click="routerLink('merchant/merchantinfo/merchantList',null)">商家管理</a>
                </div>
              </el-col>
              <el-col :span="5">
                <img src="../assets/index_images/shop_sack_icon.png" class="img-icon"/>
                <div class="img-icon-title">
                <a @click="routerLink('merchant/shop/index',null)">供应商管理</a>
                </div>
              </el-col>
              <el-col :span="5">
                <img src="../assets/index_images/home_icon.png" class="img-icon"/>
                <div class="img-icon-title">
                <a @click="routerLink('product/ticket/index',null)">资源管理</a>
                </div>
              </el-col>
              <el-col :span="5">
                <img src="../assets/index_images/money_icon.png" class="img-icon"/>
                <div class="img-icon-title">
                <a @click="routerLink('distributor/distributorList',null)">分销管理</a>
                </div>
              </el-col>
              <el-col :span="4">
                <img src="../assets/index_images/inventory_icon.png" class="img-icon"/>
                <div class="img-icon-title">
                <a @click="routerLink('report/teamMarketingPriceList',null)">报表管理</a>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-card>
      </el-col>
      </el-row>

    <el-row :gutter="20" v-loading="loadingResource">
      <el-col :span="12">
        <el-card >
          <div slot="header" class="clearfix">
            <span class="title-border">资源一览</span>
          </div>
          <div>
            <el-row :gutter="20" style="margin-left: 30px">
              <el-col :span="4">
                <div class="resource-num">{{comProNum}}</div>
                <div class="resource-title"><a @click="routerLink('product/combinationProducts/combinationProductsList',null)">地接线路游</a></div>
              </el-col>
              <el-col :span="4">
                <div class="resource-num">{{scenicAreaNum}}</div>
                <div class="resource-title"><a @click="routerLink('product/combinationProducts/travelTicketList',null)">门票</a></div>
              </el-col>
              <el-col :span="4">
                <div class="resource-num">{{hotelNum}}</div>
                <div class="resource-title"><a @click="routerLink('product/hotel/hotelList',null)">住宿</a></div>
              </el-col>
              <el-col :span="4">
                <div class="resource-num">{{cateringNum}}</div>
                <div class="resource-title"><a @click="routerLink('product/catering/cateringList',null)">餐饮</a></div>
              </el-col>
              <el-col :span="4">
                <div class="resource-num">{{guideNum}}</div>
                <div class="resource-title"><a @click="routerLink('product/guide/guideList',null)">导游</a></div>
              </el-col>
              <el-col :span="4">
                <div class="resource-num">{{carNum}}</div>
                <div class="resource-title"><a @click="routerLink('product/car/carInfoList',null)">车辆</a></div>
              </el-col>
            </el-row>
          </div>
        </el-card>
      </el-col>

      <el-col :span="12" v-loading="loadingStay">
        <el-card >
          <div slot="header" class="clearfix">
            <span class="title-border">待审核</span>
          </div>
          <div>
            <el-row :gutter="90" style="margin-left: 30px">
              <el-col :span="8">
                <div class="resource-num">{{settleNum}}</div>
                <div class="resource-title"><a @click="routerLink('groupTravel/advance/settlement',null)">结算申请</a></div>
              </el-col>
              <el-col :span="8">
                <div class="resource-num">{{orderCheckNum}}</div>
                <div class="resource-title"><a @click="routerLink('order/order-travelAudit-list')">订单核销申请</a></div>
              </el-col>
              <el-col :span="8">
                <div class="resource-num">{{userMoneyNum}}</div>
                <div class="resource-title"><a @click="routerLink('groupTravel/advance/info',null)">用款申请</a></div>
              </el-col>
            </el-row>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <el-row v-loading="loadingOrderFailLog">
      <el-col :span="24">
        <el-card >
          <div slot="header" class="clearfix">
            <span class="title-border">异常订单日志</span>
          </div>
          <a @click="routerLink('order/order-fail-log-list')">
            <el-table v-loading="loadingOrderFailLog" :data="orderFailLogs">
              <el-table-column label="主键" align="center" prop="orderFailLogId" />
              <el-table-column label="订单号" align="center" prop="orderForeignNo" />
              <el-table-column label="类型" align="center" prop="type">
                <template slot-scope="scope">
                  <dict-tag :options="dict.type.order_fail_log_type" :value="scope.row.type"/>
                </template>
              </el-table-column>
              <el-table-column label="状态" align="center" prop="status">
                <template slot-scope="scope">
                  <dict-tag :options="dict.type.order_fail_log_status" :value="scope.row.status"/>
                </template>
              </el-table-column>
              <el-table-column label="描述" align="center" prop="description" />
              <el-table-column label="创建日期" align="center" prop="createDate" width="180">
                <template slot-scope="scope">
                  <span>{{ parseTime(scope.row.createDate, '{y}-{m}-{d}') }}</span>
                </template>
              </el-table-column>
            </el-table>
          </a>
        </el-card>
      </el-col>
    </el-row>

      <el-row :gutter="20">
      <el-col :span="12" v-loading="loadingSale">
    <el-card>
      <div slot="header" class="clearfix">
        <span class="title-border">销售分析</span>
        <el-button style="float: right; padding: 3px 0" type="text" @click="resetQuery">/ 重 置</el-button>
        <el-button style="float: right; padding: 3px 0" type="text" @click="saleAnalyse">查 询 </el-button>
      </div>
    <div class="maps">
      <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" style="padding-left: 60px">
        <el-form-item prop="dateType">
          <el-select v-model="queryParams.dateType" placeholder="请选择日期类型" clearable>
            <el-option
              v-for="dict in dict.type.sale_analyse_type"
              :key="dict.value"
              :label="dict.label"
              :value="dict.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="daterange"
            style="width: 240px"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <div id="productEchart" class="maps-echart"></div>
    </div>
    </el-card>
      </el-col>
      <el-col :span="12" v-loading="loadingMaps">
        <el-card >
          <div slot="header" class="clearfix">
            <span class="title-border">客源地分析</span>
          </div>
    <div class="maps">
      <div class="zEcharts">
        <span class="person-count" style="padding-left: 30px;font-size: 14px">人数统计</span>
        <div class="map-container" id="myEcharts" ref="myEchart"
             style="margin-left: 10%;width:450px; height: 450px;"></div>
      </div>
    </div>
        </el-card>
      </el-col>
      </el-row>
  </div>
</template>

<script>
import 'echarts/map/js/china.js'
import dayjs from "dayjs";
import {
  orderStatusCount,areaCount,orderBlocCount
} from "@/api/order/order-travel-declare-order";
import {carCount} from"@/api/car/car_info"
import {cateringCount} from '@/api/catering/catering_info'
import {guideCount} from '@/api/guide/guide'
import {hotelCount} from '@/api/hotel/hotel_info'
import {comProCount} from '@/api/combinationProducts/combinationProducts'
import {getTravelCount} from '@/api/merchant/merGoodsGdistributor'
import {auditProcessCount} from '@/api/group-travel/orderSettleRecord'
import {countTravelOrderAuditId} from '@/api/order/order-travel-audit-log'
import {capPaymentAuditCount} from '@/api/paymentApply/audit'
import {saleAnalyse} from '@/api/order/order-travel-declare-order'
import {areaListByPid} from "@/api/merchant/merArea";
import {countMerchant} from '@/api/merchant/merchant'
import {listCount} from '@/api/order/order-fail-log'

export default {
  name: "index",
  dicts: ['sale_analyse_type','order_fail_log_type', 'order_fail_log_status'],
  data() {
    return {
      loadingOrder:false,
      loadingResource:false,
      loadingOrderStatus:false,
      loadingStay:false,
      loadingOrderFailLog:false,
      loadingSale:false,
      loadingMaps:false,
      // 查询条件
      queryParams:{
         dateType:'1',
         startDate:dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
         endDate:dayjs().format('YYYY-MM-DD'),
      },
      // 时间区间
      daterange:[
        dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
        dayjs().format('YYYY-MM-DD')
      ],
      // 当前时间（数据截止时间）
      dateTime:dayjs().format('YYYY-MM-DD HH:mm:ss'),
      // 版本号
      version: "2.4.0",
      // 第一排统计数据
      countMer: null,
      countShop: null,
      checkNum: null,
      orderNum: null,
      //供应商数量
      comProNum:0,
      //景区数量
      scenicAreaNum:0,
      //餐饮数量
      cateringNum:0,
      //酒店数量
      hotelNum:0,
      //导游数量
      guideNum:0,
      //车队数量
      carNum:0,
      //已接单
      receivingNum:0,
      //安排在
      arrangeNum:0,
      //已落实
      practicableNum:0,
      //结算申请
      settleNum:0,
      //订单核销申请
      orderCheckNum:0,
      //用款申请
      userMoneyNum:0,
      //异常订单日志
      orderFailLogs:[],
      //中国地图参数
      optionMaps: {
        tooltip: {
          trigger: "item",
        },
        // title: {
        //   show: true, //是否显示
        //   text: "客源地分析",
        // },
        legend: {
          orient: "horizontal", //图例的排列方向
          textStyle: { color: "#3acc1c" },
          x: "left", //图例的位置
          y: "-20000000000000",
          data: []
        },
        visualMap: {
          //颜色的设置  dataRange
          textStyle: { color: "#171d1a" },
          x: "left",
          y: "bottom",
          splitList: [
            { start: 500, color: "#1221e1" },
            { start: 300, end: 500, color: "#3847e1" },
            { start: 11, end: 300, color: "#606de1" },
            { start: 1, end: 10, color: "#99a2e1" }
          ],
          show: true,
          // text:['高','低'],// 文本，默认为数值文本
          color: ['rgb(50,88,124)']
          // color: [
          //     "#5475f5",
          //     "#9feaa5",
          //     "#3FA7FF",
          //     "#66E0E3",
          //     "#FFDC5E",
          //     "#9fb5ea"
          // ]
        },
        roamController: {//控制地图的上下左右放大缩小
          show: true,
          x: 'right',
          mapTypeControl: {
            'china': true
          }
        },
        series: [
          {
            data: [],
            top: "30", //组件距离容器的距离
            name: "人数",
            type: "map",
            mapType: "china",
            zoom: 1.22,//地图大小
            roam: false, //是否开启鼠标缩放和平移漫游
            itemStyle: {
              //地图区域的多边形 图形样式
              normal: {
                //是图形在默认状态下的样式
                label: {
                  show: true,
                  textStyle: { color: "rgb(0, 0, 0)" }//地图text样式
                },
              },
              emphasis: {
                //是图形在高亮状态下的样式,比如在鼠标悬浮或者图例联动高亮时
                label: { show: true }
              }
            },
          }
        ]
      },
      maps:[],
      optionProduct:{
        tooltip: {
          trigger: "item",
        },
        // title: {
        //   show: true, //是否显示
        //   text: "销售分析",
        // },
        grid: {containLabel: false},
        xAxis: {
          name: '日期',
          data:[]
        },
        yAxis: {
          name: '金额',
        },
        series: [
          {
            type: 'line',
            stack: 'Total',
            smooth: false,
            data: [],
            itemStyle: {
              normal: {
                lineStyle:{color:'#00a0e9'}
              }
            },
          },
        ]
      },
    }
  },
  mounted() {
    this.countMerchant();
    this.resourceCount();
    this.orderStatusCount();
    this.stayAudit();
    this.getOrderFailLogs();
    this.saleAnalyse();
    this.areaCountNum();
  },
  watch: {

  },
  methods: {
    routerLink(url,param){
      if(param!=null){
        this.$router.push({path:url,query:param});
      }else{
        this.$router.push({path:url});
      }
    },

    //异常订单日志
    getOrderFailLogs(){
      this.loadingOrderFailLog=true;
      listCount().then(response=>{
        this.orderFailLogs=response.data;
        this.loadingOrderFailLog=false;
      })
    },

    //销售分析
    saleAnalyse(){
      this.loadingSale = true;
      let productEchart = this.$echarts.init(document.getElementById('productEchart'))
      if(this.queryParams.dateType==null || this.queryParams.dateType==''){
        this.$modal.msgError("日期类型必选！");
        this.loadingSale = false;
        return;
      }
      if(this.daterange==null || this.daterange=='' || this.daterange.length==0){
        this.$modal.msgError("查询日期必选！");
        this.loadingSale = false;
        return;
      }
      this.queryParams.startDate=this.daterange[0];
      this.queryParams.endDate=this.daterange[1];
      saleAnalyse(this.queryParams).then(response => {
        this.optionProduct.xAxis.data=response.data.dateTimeList;
        this.optionProduct.series[0].data=response.data.saleMoneyList;
        productEchart.setOption(this.optionProduct);
        this.loadingSale = false;
      })
    },

    resetQuery(){
      var startDate = dayjs().subtract(1, 'month').format('YYYY-MM-DD');
      var endDate = dayjs().format('YYYY-MM-DD');
      this.queryParams = {
          dateType:'1',
          startDate:startDate,
          endDate:endDate,
      };
      this.daterange=[
        dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
        dayjs().format('YYYY-MM-DD')
      ];
      this.saleAnalyse();
    },

    //顶部订单统计
    countMerchant(){
      this.loadingOrder = true;
      countMerchant().then(response => {
        this.countMer = response.data.countMer;
        this.countShop = response.data.countShop;
      })

      orderBlocCount().then(response => {
        this.checkNum = response.data.checkNum;
        this.orderNum = response.data.orderNum;
        this.loadingOrder = false;
      }).catch(res => {
        this.loadingOrder = false;
      })
    },

    //代办事项
    orderStatusCount(){
      this.loadingOrderStatus = true;
      orderStatusCount().then(response => {
        this.receivingNum = response.data.receivingNum;
        this.arrangeNum = response.data.arrangeNum;
        this.practicableNum = response.data.practicableNum;
        this.loadingOrderStatus = false;
      }).catch(res => {
        this.loadingOrderStatus = false;
      })
    },

    /** 查询省级区域列表 */
    getProvinceList() {
      areaListByPid(0).then(response => {
        this.provinceList = response.data;
      });
    },

    //资源一览
    resourceCount(){
      this.loadingResource = true;
      carCount().then(response => {
        this.carNum=response.data!=null?response.data:0;
      })
      cateringCount().then(response => {
        this.cateringNum=response.data!=null?response.data:0;
      })
      guideCount().then(response => {
        this.guideNum=response.data!=null?response.data:0;
      })
      hotelCount().then(response => {
        this.hotelNum=response.data!=null?response.data:0;
      })
      comProCount().then(response => {
        this.comProNum=response.data!=null?response.data:0;
      })
      getTravelCount().then(response => {
        this.scenicAreaNum=response.data!=null?response.data:0;
        this.loadingResource = false;
      }).catch(res => {
        this.loadingResource = false;
      })
    },

    //待审核
    stayAudit(){
        this.loadingStay = true;

        auditProcessCount().then(response => {
          this.settleNum=response.data;
          this.loadingStay = false;
        }).catch(res => {
          this.loadingStay = false;
        })

        countTravelOrderAuditId().then(response => {
          this.orderCheckNum=response.data;
          this.loadingStay = false;
        }).catch(res => {
          this.loadingStay = false;
        })

        capPaymentAuditCount().then(response => {
          this.userMoneyNum=response.data;
          this.loadingStay = false;
        }).catch(res => {
          this.loadingStay = false;
        })
    },

    areaCountNum(){
      this.loadingMaps = true;
      areaListByPid(0).then(response => {
        let area = response.data;
        areaCount().then(response => {
          let areaNum = response.data;
          let arr = [];
          areaNum.forEach(item=>{
            area.forEach(a=>{
              if(item.areaCode == a.id.toString().substr(0,2)){
                arr.push({name:a.name,value:item.num})
              }
            })
          })
         this.initEcharts(arr);
        }).catch(res => {
          this.loadingMaps = false;
        });
      }).catch(res => {
        this.loadingMaps = false;
      });
    },

    initEcharts(arry) {
      let mapDiv = document.getElementById('myEcharts')
      let chinaMapChart = this.$echarts.init(mapDiv)
      this.optionMaps.series[0].data = arry;
      chinaMapChart.setOption(this.optionMaps,true);
      this.loadingMaps = false;
    },

  },
};
</script>

<style scoped lang="scss">

.zEcharts {
  width: 100%;
  height: 100%;
}

.figure {
  height: 250px;
  width: 50%;
  float: left;
  margin-top: 10%;
}

.maps {
  height: 480px;
  width: 100%;
  float: left;
  margin-top: 10px;
}

.maps-echart{
  height: 430px;
  width: 100%;
}



.lattice {
  float: left;
  line-height: 50px;
  width: 152px;
  margin: 0px 22px;
  height: 50px;
  color: #fff;
  align-content: center;
  text-align: center;
  background-color: #368E8E;
}
.titleOne{
  background-image: url("../assets/index_images/titleOne.png");
}
.titleTwo{
  background-image: url("../assets/index_images/titleTwo.png");
}
.titleThree{
  background-image: url("../assets/index_images/titleThree.png");
}
.titleFour{
  background-image: url("../assets/index_images/titleFour.png");
}
.xsorder{
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 130px;
  width: 95%;
  color: #ffffff;
}
.xsorder span {
  margin-left: 12%;
}
.header-top-font{
  padding-top: 10%;
  padding-left: 25%;
}
.fontsize{
  font-size: 40px!important;
  padding-top: 7%;
  padding-left: 10%;
}
.title-border{
  border-left: 5px solid #1890FF;
  padding-left: 10px;
  font-weight: bold;
}
.person-count{
  color: #9AA6B2;
}
.el-row {
  margin-bottom: 20px;
}
.header-title{
  font-size: 14px;
  color: #9AA6B2;
  padding-left: 82.5%;
}
.img-icon{
  width: 50px;
  height: 50px;
}

.img-icon-title{
  font-size: 18px;
  margin-left: 65px;
  margin-top: -40px;
}

.resource-num{
  font-size: 20px;
  font-weight: bold;
}
.resource-title{
  font-size: 12px;
  margin-top: 20px;
}
.el-card{
  border-radius: 15px;
}
</style>

