var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login" },
    [
      _c(
        "el-form",
        {
          ref: "loginForm",
          staticClass: "login-form",
          attrs: { model: _vm.loginForm, rules: _vm.loginRules },
        },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 13 } }, [
                _c("div", [
                  _c("img", {
                    attrs: {
                      src: require("../assets/images/login-left-img.jpg"),
                    },
                  }),
                ]),
              ]),
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "text-align": "center",
                        "margin-bottom": "30px",
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("../assets/images/login-logo.png"),
                        },
                      }),
                    ]
                  ),
                  _c("h3", { staticClass: "title" }, [
                    _vm._v("黔江旅游资源整合平台"),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "username" } },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: {
                            type: "text",
                            "auto-complete": "off",
                            placeholder: "账号",
                          },
                          model: {
                            value: _vm.loginForm.username,
                            callback: function ($$v) {
                              _vm.$set(_vm.loginForm, "username", $$v)
                            },
                            expression: "loginForm.username",
                          },
                        },
                        [
                          _c("svg-icon", {
                            staticClass: "el-input__icon input-icon",
                            attrs: { slot: "prefix", "icon-class": "user" },
                            slot: "prefix",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "password" } },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: {
                            type: "password",
                            "auto-complete": "off",
                            placeholder: "密码",
                          },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.handleLogin($event)
                            },
                          },
                          model: {
                            value: _vm.loginForm.password,
                            callback: function ($$v) {
                              _vm.$set(_vm.loginForm, "password", $$v)
                            },
                            expression: "loginForm.password",
                          },
                        },
                        [
                          _c("svg-icon", {
                            staticClass: "el-input__icon input-icon",
                            attrs: { slot: "prefix", "icon-class": "password" },
                            slot: "prefix",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.captchaOnOff
                    ? _c(
                        "el-form-item",
                        { attrs: { prop: "code" } },
                        [
                          _c(
                            "el-input",
                            {
                              staticStyle: { width: "39%", float: "left" },
                              attrs: {
                                "auto-complete": "off",
                                placeholder: "验证码",
                              },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.handleLogin($event)
                                },
                              },
                              model: {
                                value: _vm.loginForm.code,
                                callback: function ($$v) {
                                  _vm.$set(_vm.loginForm, "code", $$v)
                                },
                                expression: "loginForm.code",
                              },
                            },
                            [
                              _c("svg-icon", {
                                staticClass: "el-input__icon input-icon",
                                attrs: {
                                  slot: "prefix",
                                  "icon-class": "validCode",
                                },
                                slot: "prefix",
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "login-code",
                              staticStyle: { float: "left", width: "30%" },
                            },
                            [
                              _c("img", {
                                staticClass: "login-code-img",
                                attrs: { src: _vm.codeUrl },
                                on: { click: _vm.getCode },
                              }),
                            ]
                          ),
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                width: "30%",
                                height: "37px",
                                float: "left",
                                margin: "0 auto",
                                "background-image":
                                  "linear-gradient(#2aaa9d,#41aa82)",
                                "border-radius": "32px",
                              },
                              attrs: {
                                loading: _vm.loading,
                                size: "medium",
                                type: "primary",
                              },
                              nativeOn: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.handleLogin($event)
                                },
                              },
                            },
                            [
                              !_vm.loading
                                ? _c("span", [_vm._v("登 录")])
                                : _c("span", [_vm._v("登录中...")]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-checkbox",
                    {
                      staticStyle: { margin: "0px 0px 25px 0px" },
                      model: {
                        value: _vm.loginForm.rememberMe,
                        callback: function ($$v) {
                          _vm.$set(_vm.loginForm, "rememberMe", $$v)
                        },
                        expression: "loginForm.rememberMe",
                      },
                    },
                    [_vm._v("记住密码")]
                  ),
                  _c(
                    "el-form-item",
                    { staticStyle: { width: "53%", margin: "0 auto" } },
                    [
                      _vm.register
                        ? _c(
                            "div",
                            { staticStyle: { float: "right" } },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "link-type",
                                  attrs: { to: "/register" },
                                },
                                [_vm._v("立即注册")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("img", {
                        staticStyle: {
                          width: "100%",
                          margin: "0 auto",
                          "text-align": "center",
                        },
                        attrs: { src: require("../assets/images/small.jpg") },
                      }),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "100%",
                            height: "50px",
                            margin: "0 auto",
                            "text-align": "center",
                            color: "#707070",
                          },
                        },
                        [_c("span", [_vm._v("平台移动端")])]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._m(0),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "el-login-footer" }, [
      _c("span", [_vm._v("Copyright © ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }