import request from '@/utils/request'

// 查询商家信息列表
export function listMerchant(query) {
  return request({
    url: '/merchant/merchant/list',
    method: 'get',
    params: query
  })
}

// 查询商家信息详细
export function getMerchant(merchantId) {
  return request({
    url: '/merchant/merchant/' + merchantId,
    method: 'get'
  })
}

// 新增商家信息
export function addMerchant(data) {
  return request({
    url: '/merchant/merchant',
    method: 'post',
    data: data
  })
}

// 修改商家信息
export function updateMerchant(data) {
  return request({
    url: '/merchant/merchant',
    method: 'put',
    data: data
  })
}

// 冻结商家信息
export function freezeMerchant(merchantId) {
  return request({
    url: '/merchant/merchant/freeze-merchant/' + merchantId,
    method: 'put'
  })
}

// 解冻商家信息
export function unFreezeMerchant(merchantId) {
  return request({
    url: '/merchant/merchant/un-freeze-merchant/' + merchantId,
    method: 'put'
  })
}

// 查询商家信息列表(所有)
export function listAllMerchant(query) {
  return request({
    url: '/merchant/merchant/listAll',
    method: 'get',
    params: query
  })
}

// 查询商家信息列表(所有不含登录权限)
export function listAllMerchantNotPermission(query) {
  return request({
    url: '/merchant/merchant/listAllNotPermission',
    method: 'get',
    params: query
  })
}

// 查询商家信息列表(旅行社)
export function listAllMerchantTravel(query) {
  return request({
    url: '/merchant/merchant/listAllTravel',
    method: 'get',
    params: query
  })
}

// 查询商家信息和开通的服务
export function getInfoAndService() {
  return request({
    url: '/merchant/merchant/get-info-and-service',
    method: 'get'
  })
}

// 查询登录用户所属商户信息
export function getLoginDept() {
  return request({
    url: '/merchant/merchant/login-dept',
    method: 'get'
  })
}

// 查询登录用户所属商户信息
export function getLoginMerchant() {
  return request({
    url: '/merchant/merchant/login-merchant',
    method: 'get'
  })
}

// 查询登录用户集团下的地接旅行社
export function getLoginTravelMerchant() {
  return request({
    url: '/merchant/merchant/login-travel',
    method: 'get'
  })
}

// 查询当前商户可授信商户列表(所有,包含当前旅行社商户)
export function listCredibleMerchantByMerchant(query) {
  return request({
    url: '/merchant/merchant/list-credible-merchant-by-merchant-distributorid',
    method: 'get',
    params: query
  })
}

// 查询当前集团有多少个商户和店铺
export function countMerchant() {
  return request({
    url: '/merchant/merchant/countMerchant',
    method: 'get'
  })
}

