var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container",
      staticStyle: { "background-color": "#F5F4F6" },
    },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c("el-card", [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loadingOrder,
                        expression: "loadingOrder",
                      },
                    ],
                    staticStyle: { width: "100%", float: "left" },
                  },
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 15 } },
                      [
                        _c("el-col", { attrs: { span: 6 } }, [
                          _c("div", { staticClass: "titleOne xsorder" }, [
                            _c("div", { staticClass: "header-top-font" }, [
                              _vm._v(" 商户量 "),
                            ]),
                            _c("div", { staticClass: " fontsize" }, [
                              _vm._v(" " + _vm._s(_vm.countMer) + " "),
                            ]),
                          ]),
                        ]),
                        _c("el-col", { attrs: { span: 6 } }, [
                          _c("div", { staticClass: "titleTwo xsorder" }, [
                            _c("div", { staticClass: "header-top-font" }, [
                              _vm._v(" 供应商量 "),
                            ]),
                            _c("div", { staticClass: "fontsize" }, [
                              _vm._v(" " + _vm._s(_vm.countShop) + " "),
                            ]),
                          ]),
                        ]),
                        _c("el-col", { attrs: { span: 6 } }, [
                          _c("div", { staticClass: "titleThree xsorder" }, [
                            _c("div", { staticClass: "header-top-font" }, [
                              _vm._v(" 订单量 "),
                            ]),
                            _c("div", { staticClass: " fontsize" }, [
                              _vm._v(" " + _vm._s(_vm.orderNum) + " "),
                            ]),
                          ]),
                        ]),
                        _c("el-col", { attrs: { span: 6 } }, [
                          _c("div", { staticClass: "titleFour xsorder" }, [
                            _c("div", { staticClass: "header-top-font" }, [
                              _vm._v(" 核销量 "),
                            ]),
                            _c("div", { staticClass: "fontsize" }, [
                              _vm._v(" " + _vm._s(_vm.checkNum) + " "),
                            ]),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "header-title" }, [
                  _vm._v("数据截止：" + _vm._s(_vm.dateTime)),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c("el-card", [
                _c(
                  "div",
                  {
                    staticClass: "clearfix",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [
                    _c("span", { staticClass: "title-border" }, [
                      _vm._v("常用功能"),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  [
                    _c(
                      "el-row",
                      {
                        staticStyle: { "margin-left": "30px" },
                        attrs: { gutter: 20 },
                      },
                      [
                        _c("el-col", { attrs: { span: 5 } }, [
                          _c("img", {
                            staticClass: "img-icon",
                            attrs: {
                              src: require("../assets/index_images/shop_icon.png"),
                            },
                          }),
                          _c("div", { staticClass: "img-icon-title" }, [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.routerLink(
                                      "merchant/merchantinfo/merchantList",
                                      null
                                    )
                                  },
                                },
                              },
                              [_vm._v("商家管理")]
                            ),
                          ]),
                        ]),
                        _c("el-col", { attrs: { span: 5 } }, [
                          _c("img", {
                            staticClass: "img-icon",
                            attrs: {
                              src: require("../assets/index_images/shop_sack_icon.png"),
                            },
                          }),
                          _c("div", { staticClass: "img-icon-title" }, [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.routerLink(
                                      "merchant/shop/index",
                                      null
                                    )
                                  },
                                },
                              },
                              [_vm._v("供应商管理")]
                            ),
                          ]),
                        ]),
                        _c("el-col", { attrs: { span: 5 } }, [
                          _c("img", {
                            staticClass: "img-icon",
                            attrs: {
                              src: require("../assets/index_images/home_icon.png"),
                            },
                          }),
                          _c("div", { staticClass: "img-icon-title" }, [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.routerLink(
                                      "product/ticket/index",
                                      null
                                    )
                                  },
                                },
                              },
                              [_vm._v("资源管理")]
                            ),
                          ]),
                        ]),
                        _c("el-col", { attrs: { span: 5 } }, [
                          _c("img", {
                            staticClass: "img-icon",
                            attrs: {
                              src: require("../assets/index_images/money_icon.png"),
                            },
                          }),
                          _c("div", { staticClass: "img-icon-title" }, [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.routerLink(
                                      "distributor/distributorList",
                                      null
                                    )
                                  },
                                },
                              },
                              [_vm._v("分销管理")]
                            ),
                          ]),
                        ]),
                        _c("el-col", { attrs: { span: 4 } }, [
                          _c("img", {
                            staticClass: "img-icon",
                            attrs: {
                              src: require("../assets/index_images/inventory_icon.png"),
                            },
                          }),
                          _c("div", { staticClass: "img-icon-title" }, [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.routerLink(
                                      "report/teamMarketingPriceList",
                                      null
                                    )
                                  },
                                },
                              },
                              [_vm._v("报表管理")]
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loadingResource,
              expression: "loadingResource",
            },
          ],
          attrs: { gutter: 20 },
        },
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("el-card", [
                _c(
                  "div",
                  {
                    staticClass: "clearfix",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [
                    _c("span", { staticClass: "title-border" }, [
                      _vm._v("资源一览"),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  [
                    _c(
                      "el-row",
                      {
                        staticStyle: { "margin-left": "30px" },
                        attrs: { gutter: 20 },
                      },
                      [
                        _c("el-col", { attrs: { span: 4 } }, [
                          _c("div", { staticClass: "resource-num" }, [
                            _vm._v(_vm._s(_vm.comProNum)),
                          ]),
                          _c("div", { staticClass: "resource-title" }, [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.routerLink(
                                      "product/combinationProducts/combinationProductsList",
                                      null
                                    )
                                  },
                                },
                              },
                              [_vm._v("地接线路游")]
                            ),
                          ]),
                        ]),
                        _c("el-col", { attrs: { span: 4 } }, [
                          _c("div", { staticClass: "resource-num" }, [
                            _vm._v(_vm._s(_vm.scenicAreaNum)),
                          ]),
                          _c("div", { staticClass: "resource-title" }, [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.routerLink(
                                      "product/combinationProducts/travelTicketList",
                                      null
                                    )
                                  },
                                },
                              },
                              [_vm._v("门票")]
                            ),
                          ]),
                        ]),
                        _c("el-col", { attrs: { span: 4 } }, [
                          _c("div", { staticClass: "resource-num" }, [
                            _vm._v(_vm._s(_vm.hotelNum)),
                          ]),
                          _c("div", { staticClass: "resource-title" }, [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.routerLink(
                                      "product/hotel/hotelList",
                                      null
                                    )
                                  },
                                },
                              },
                              [_vm._v("住宿")]
                            ),
                          ]),
                        ]),
                        _c("el-col", { attrs: { span: 4 } }, [
                          _c("div", { staticClass: "resource-num" }, [
                            _vm._v(_vm._s(_vm.cateringNum)),
                          ]),
                          _c("div", { staticClass: "resource-title" }, [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.routerLink(
                                      "product/catering/cateringList",
                                      null
                                    )
                                  },
                                },
                              },
                              [_vm._v("餐饮")]
                            ),
                          ]),
                        ]),
                        _c("el-col", { attrs: { span: 4 } }, [
                          _c("div", { staticClass: "resource-num" }, [
                            _vm._v(_vm._s(_vm.guideNum)),
                          ]),
                          _c("div", { staticClass: "resource-title" }, [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.routerLink(
                                      "product/guide/guideList",
                                      null
                                    )
                                  },
                                },
                              },
                              [_vm._v("导游")]
                            ),
                          ]),
                        ]),
                        _c("el-col", { attrs: { span: 4 } }, [
                          _c("div", { staticClass: "resource-num" }, [
                            _vm._v(_vm._s(_vm.carNum)),
                          ]),
                          _c("div", { staticClass: "resource-title" }, [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.routerLink(
                                      "product/car/carInfoList",
                                      null
                                    )
                                  },
                                },
                              },
                              [_vm._v("车辆")]
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-col",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadingStay,
                  expression: "loadingStay",
                },
              ],
              attrs: { span: 12 },
            },
            [
              _c("el-card", [
                _c(
                  "div",
                  {
                    staticClass: "clearfix",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [
                    _c("span", { staticClass: "title-border" }, [
                      _vm._v("待审核"),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  [
                    _c(
                      "el-row",
                      {
                        staticStyle: { "margin-left": "30px" },
                        attrs: { gutter: 90 },
                      },
                      [
                        _c("el-col", { attrs: { span: 8 } }, [
                          _c("div", { staticClass: "resource-num" }, [
                            _vm._v(_vm._s(_vm.settleNum)),
                          ]),
                          _c("div", { staticClass: "resource-title" }, [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.routerLink(
                                      "groupTravel/advance/settlement",
                                      null
                                    )
                                  },
                                },
                              },
                              [_vm._v("结算申请")]
                            ),
                          ]),
                        ]),
                        _c("el-col", { attrs: { span: 8 } }, [
                          _c("div", { staticClass: "resource-num" }, [
                            _vm._v(_vm._s(_vm.orderCheckNum)),
                          ]),
                          _c("div", { staticClass: "resource-title" }, [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.routerLink(
                                      "order/order-travelAudit-list"
                                    )
                                  },
                                },
                              },
                              [_vm._v("订单核销申请")]
                            ),
                          ]),
                        ]),
                        _c("el-col", { attrs: { span: 8 } }, [
                          _c("div", { staticClass: "resource-num" }, [
                            _vm._v(_vm._s(_vm.userMoneyNum)),
                          ]),
                          _c("div", { staticClass: "resource-title" }, [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.routerLink(
                                      "groupTravel/advance/info",
                                      null
                                    )
                                  },
                                },
                              },
                              [_vm._v("用款申请")]
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loadingOrderFailLog,
              expression: "loadingOrderFailLog",
            },
          ],
        },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c("el-card", [
                _c(
                  "div",
                  {
                    staticClass: "clearfix",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [
                    _c("span", { staticClass: "title-border" }, [
                      _vm._v("异常订单日志"),
                    ]),
                  ]
                ),
                _c(
                  "a",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.routerLink("order/order-fail-log-list")
                      },
                    },
                  },
                  [
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loadingOrderFailLog,
                            expression: "loadingOrderFailLog",
                          },
                        ],
                        attrs: { data: _vm.orderFailLogs },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            label: "主键",
                            align: "center",
                            prop: "orderFailLogId",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "订单号",
                            align: "center",
                            prop: "orderForeignNo",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "类型",
                            align: "center",
                            prop: "type",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("dict-tag", {
                                    attrs: {
                                      options:
                                        _vm.dict.type.order_fail_log_type,
                                      value: scope.row.type,
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "状态",
                            align: "center",
                            prop: "status",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("dict-tag", {
                                    attrs: {
                                      options:
                                        _vm.dict.type.order_fail_log_status,
                                      value: scope.row.status,
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "描述",
                            align: "center",
                            prop: "description",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "创建日期",
                            align: "center",
                            prop: "createDate",
                            width: "180",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.parseTime(
                                          scope.row.createDate,
                                          "{y}-{m}-{d}"
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadingSale,
                  expression: "loadingSale",
                },
              ],
              attrs: { span: 12 },
            },
            [
              _c("el-card", [
                _c(
                  "div",
                  {
                    staticClass: "clearfix",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [
                    _c("span", { staticClass: "title-border" }, [
                      _vm._v("销售分析"),
                    ]),
                    _c(
                      "el-button",
                      {
                        staticStyle: { float: "right", padding: "3px 0" },
                        attrs: { type: "text" },
                        on: { click: _vm.resetQuery },
                      },
                      [_vm._v("/ 重 置")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticStyle: { float: "right", padding: "3px 0" },
                        attrs: { type: "text" },
                        on: { click: _vm.saleAnalyse },
                      },
                      [_vm._v("查 询 ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "maps" },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "queryForm",
                        staticStyle: { "padding-left": "60px" },
                        attrs: {
                          model: _vm.queryParams,
                          size: "small",
                          inline: true,
                        },
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { prop: "dateType" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: "请选择日期类型",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.queryParams.dateType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.queryParams, "dateType", $$v)
                                  },
                                  expression: "queryParams.dateType",
                                },
                              },
                              _vm._l(
                                _vm.dict.type.sale_analyse_type,
                                function (dict) {
                                  return _c("el-option", {
                                    key: dict.value,
                                    attrs: {
                                      label: dict.label,
                                      value: dict.value,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          [
                            _c("el-date-picker", {
                              staticStyle: { width: "240px" },
                              attrs: {
                                "value-format": "yyyy-MM-dd",
                                type: "daterange",
                                "range-separator": "-",
                                "start-placeholder": "开始日期",
                                "end-placeholder": "结束日期",
                              },
                              model: {
                                value: _vm.daterange,
                                callback: function ($$v) {
                                  _vm.daterange = $$v
                                },
                                expression: "daterange",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("div", {
                      staticClass: "maps-echart",
                      attrs: { id: "productEchart" },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-col",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadingMaps,
                  expression: "loadingMaps",
                },
              ],
              attrs: { span: 12 },
            },
            [
              _c("el-card", [
                _c(
                  "div",
                  {
                    staticClass: "clearfix",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [
                    _c("span", { staticClass: "title-border" }, [
                      _vm._v("客源地分析"),
                    ]),
                  ]
                ),
                _c("div", { staticClass: "maps" }, [
                  _c("div", { staticClass: "zEcharts" }, [
                    _c(
                      "span",
                      {
                        staticClass: "person-count",
                        staticStyle: {
                          "padding-left": "30px",
                          "font-size": "14px",
                        },
                      },
                      [_vm._v("人数统计")]
                    ),
                    _c("div", {
                      ref: "myEchart",
                      staticClass: "map-container",
                      staticStyle: {
                        "margin-left": "10%",
                        width: "450px",
                        height: "450px",
                      },
                      attrs: { id: "myEcharts" },
                    }),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }