import request from '@/utils/request'

// 查询导游信息列表
export function listguide(query) {
  return request({
    url: '/guide/info/list',
    method: 'get',
    params: query
  })
}

// 查询导游信息详细
export function getguide(id) {
  return request({
    url: '/guide/info/' + id,
    method: 'get'
  })
}

// 新增导游信息
export function addguide(data) {
  return request({
    url: '/guide/info',
    method: 'post',
    data: data
  })
}

// 修改导游信息
export function updateguide(data) {
  return request({
    url: '/guide/info',
    method: 'put',
    data: data
  })
}

// 删除导游信息
export function delguide(id) {
  return request({
    url: '/guide/info/' + id,
    method: 'delete'
  })
}

// 查询所有导游信息列表
export function listAllguide(query) {
  return request({
    url: '/guide/info/list-all',
    method: 'get',
    params: query
  })
}

// 查询导游数量
export function guideCount() {
  return request({
    url: '/guide/info/guideCount',
    method: 'get'
  })
}
