import {getInfo, login, logout, refreshToken} from '@/api/login'
import {getToken, removeToken, setExpiresIn, setToken} from '@/utils/auth'

const routeCacheStatus = {
  state: {
    user: false,
  },

  mutations: {
    SET_USER: (state, status) => {
      state.user = status
    },
  },

  actions: {
    setUserRouteCache({commit},status) {
          commit('SET_USER',status)
    },
  }
}

export default routeCacheStatus
