<template>

  <div>
    <div v-if="!bloc && !travel && !groupTravel && !merchant && !marketing">
      <template>
        <index-v2 />
      </template>
    </div>

    <div v-if="travel">
      <template>
        <index-travel />
      </template>
    </div>

    <div v-if="bloc">
      <template>
        <index-bloc />
      </template>
    </div>
    <div v-if="groupTravel">
      <template>
        <index-group-travel />
      </template>
    </div>
    <div v-if="merchant || marketing">
      <template>
        <index-merchant />
      </template>
    </div>
  </div>
</template>

<script>
import store from '@/store';
import indexV2 from '@/views/index_v2';
import indexTravel from '@/views/index_travel';
import indexBloc from '@/views/index_bloc';
import indexGroupTravel from '@/views/index_group_travel';
import indexMerchant from '@/views/index_merchant'
export default {
  components: {indexV2,indexTravel,indexBloc,indexGroupTravel,indexMerchant},
  name: 'index',
  data() {
    return {
      bloc:store.getters.roles.includes('bloc'),
      travel:store.getters.roles.includes('travel'),
      groupTravel:store.getters.roles.includes('group_travel'),
      merchant:store.getters.roles.includes('merchant'),
      marketing:store.getters.roles.includes('marketing'),
    }
  },
}


</script>

<style scoped>

</style>
