import request from '@/utils/request'

// 查询项目列表
export function listinfo(query) {
  return request({
    url: '/project/project-info/list',
    method: 'get',
    params: query
  })
}

// 查询项目详细
export function getinfo(id) {
  return request({
    url: '/project/project-info/' + id,
    method: 'get'
  })
}

// 新增项目
export function addinfo(data) {
  return request({
    url: '/project/project-info',
    method: 'post',
    data: data
  })
}

// 修改项目
export function updateinfo(data) {
  return request({
    url: '/project/project-info',
    method: 'put',
    data: data
  })
}

// 删除项目
export function delinfo(id) {
  return request({
    url: '/project/project-info/' + id,
    method: 'delete'
  })
}

// 查询所有项目列表
export function listAllinfo(query) {
  return request({
    url: '/project/project-info/list-all',
    method: 'get',
    params: query
  })
}

// 查询项目数量
export function projectCount() {
  return request({
    url: '/project/project-info/projectCount',
    method: 'get'
  })
}
