import request from '@/utils/request'

// 查询组团旅行社列表
export function listAdvanceAccount(query) {
  return request({
    url: '/travel/advance-account/list',
    method: 'get',
    params: query
  })
}

// 查询组团旅行社余额之和
export function accountBalanceSum(query) {
  return request({
    url: '/travel/advance-account/balance-sum',
    method: 'get',
    params: query
  })
}

// 查询组团旅行社调账明细
export function listAdvanceDetail(query) {
  return request({
    url: '/travel/advance-account/list-detail',
    method: 'get',
    params: query
  })
}

// 查询组团旅行社详细
export function getAdvanceAccount(id) {
  return request({
    url: '/travel/advance-account/' + id,
    method: 'get'
  })
}

// 新增组团旅行社
export function addAdvanceAccount(data) {
  return request({
    url: '/travel/advance-account',
    method: 'post',
    data: data
  })
}

// 人工调账
export function regulateAdvanceAccount(data) {
  return request({
    url: '/travel/advance-account/regulate',
    method: 'put',
    data: data
  })
}

// 启用停用账号
export function upDown(data) {
  return request({
    url: '/travel/advance-account/up-down',
    method: 'put',
    data: data
  })
}

//获取预付款详细信息（含基础信息）
export function getAdanceDeatil() {
  return request({
    url: '/travel/advance-account/getAdanceDeatil',
    method: 'get'
  })
}
