var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: { "has-logo": _vm.showLogo },
      style: {
        backgroundColor:
          _vm.settings.sideTheme === "theme-dark"
            ? _vm.variables.menuBackground
            : _vm.variables.menuLightBackground,
      },
    },
    [
      _vm.showLogo
        ? _c("logo", { attrs: { collapse: _vm.isCollapse } })
        : _vm._e(),
      _c(
        "el-scrollbar",
        {
          class: _vm.settings.sideTheme,
          attrs: { "wrap-class": "scrollbar-wrapper" },
        },
        [
          _c(
            "div",
            {
              attrs: { id: "disFlex" },
              on: {
                mouseleave: function ($event) {
                  return _vm.leaveItem()
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "leftBox",
                  style:
                    "background-color:" +
                    (_vm.settings.sideTheme === "theme-dark"
                      ? _vm.variables.menuBackground
                      : _vm.variables.menuLightBackground),
                },
                _vm._l(_vm.routeList, function (route, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "item",
                      class: { active: _vm.activeIndex === index },
                      on: {
                        click: function ($event) {
                          return _vm.activeItem(index, route)
                        },
                      },
                    },
                    [
                      route.meta
                        ? _c("svg-icon", {
                            attrs: {
                              "icon-class": route.meta && route.meta.icon,
                            },
                          })
                        : _vm._e(),
                      route.name
                        ? _c(
                            "span",
                            {
                              style:
                                "color:" +
                                (_vm.settings.sideTheme === "theme-dark"
                                  ? _vm.variables.menuColor
                                  : _vm.variables.menuLightColor),
                            },
                            [_vm._v(" " + _vm._s(route.meta.title) + " ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                0
              ),
              _vm.activeMenu
                ? _c(
                    "el-menu",
                    {
                      attrs: {
                        "default-active": _vm.activeMenu,
                        collapse: _vm.isCollapse,
                        "background-color":
                          _vm.settings.sideTheme === "theme-dark"
                            ? _vm.variables.menuBackground
                            : _vm.variables.menuLightBackground,
                        "text-color":
                          _vm.settings.sideTheme === "theme-dark"
                            ? _vm.variables.menuColor
                            : _vm.variables.menuLightColor,
                        "unique-opened": true,
                        "active-text-color": _vm.settings.theme,
                        "collapse-transition": false,
                        mode: "vertical",
                      },
                    },
                    _vm._l(_vm.routeItem.children, function (route, index) {
                      return _c("sidebar-item", {
                        key: route.path + index,
                        attrs: { item: route, "base-path": route.path },
                      })
                    }),
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }