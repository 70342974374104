var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container home" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [_c("el-col", { attrs: { sm: 24, lg: 24 } }, [_c("hr")])],
        1
      ),
      _c("el-row", { attrs: { gutter: 20 } }),
      _c("el-divider"),
      _c("el-row", { attrs: { gutter: 20 } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }