/* 唯一角色权限标识*/
export const SYS_ROLE_KEY = {
  bloc: "bloc",
  merchant: "merchant",
  shop: "shop",
  group_travel: "group_travel",
  travel: "travel",
  marketing: "marketing",
}
export default {
  SYS_ROLE_KEY
}
