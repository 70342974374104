import request from '@/utils/request'

// 查询用款申请列表
export function listinfo(query) {
  return request({
    url: '/paymentApply/info/list',
    method: 'get',
    params: query
  })
}

// 查询用款申请详细
export function getinfo(capPaymentApplyId,type) {
  return request({
    url: '/paymentApply/info/' + capPaymentApplyId+'/'+type,
    method: 'get'
  })
}

// 新增用款申请
export function addinfo(data) {
  return request({
    url: '/paymentApply/info',
    method: 'post',
    data: data
  })
}

// 修改用款申请
export function updateinfo(data) {
  return request({
    url: '/paymentApply/info',
    method: 'put',
    data: data
  })
}

// 送审
export function sendAudit(capPaymentApplyId) {
  return request({
    url: '/paymentApply/info/sendAudit/'+capPaymentApplyId,
    method: 'post',
  })
}

// 审批
export function audit(capPaymentApplyId,isOk,rejectMsg) {
  return request({
    url: '/paymentApply/info/audit/'+capPaymentApplyId+'/'+isOk+"?rejectMsg="+rejectMsg,
    method: 'post',
  })
}

// 审批
export function press(capPaymentApplyId) {
  return request({
    url: '/paymentApply/info/press/'+capPaymentApplyId,
    method: 'post',
  })
}

//上传汇款凭证
export function fileVoucher(data) {
  return request({
    url: '/paymentApply/info/fileVoucher',
    method: 'post',
    data: data
  })
}

// 删除用款申请
export function delinfo(capPaymentApplyId) {
  return request({
    url: '/paymentApply/info/' + capPaymentApplyId,
    method: 'delete'
  })
}

// 查询所有用款申请列表
export function listAllinfo(query) {
  return request({
    url: '/paymentApply/info/list-all',
    method: 'get',
    params: query
  })
}

// 查询用款申请与结算申请的待办事项
export function beDoing() {
  return request({
    url: '/paymentApply/payment-apply-small-api/travel-order-in-audit-list',
    method: 'get'
  })
}

// 查询用款申请与结算申请的在办事项
export function commission() {
  return request({
    url: '/paymentApply/payment-apply-small-api/travel-order-wait-audit-list',
    method: 'get'
  })
}

