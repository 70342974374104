import request from '@/utils/request'

// 查询酒店信息列表
export function listhotel_info(query) {
  return request({
    url: '/hotel/hotel_info/list',
    method: 'get',
    params: query
  })
}

// 查询酒店信息详细
export function gethotel_info(id) {
  return request({
    url: '/hotel/hotel_info/' + id,
    method: 'get'
  })
}

// 新增酒店信息
export function addhotel_info(data) {
  return request({
    url: '/hotel/hotel_info',
    method: 'post',
    data: data
  })
}

// 修改酒店信息
export function updatehotel_info(data) {
  return request({
    url: '/hotel/hotel_info',
    method: 'put',
    data: data
  })
}

// 修改酒店信息
export function updatehotelInfoStatus(data) {
  return request({
    url: '/hotel/hotel_info/editStatus',
    method: 'post',
    data: data
  })
}

// 删除酒店信息
export function delhotel_info(id) {
  return request({
    url: '/hotel/hotel_info/' + id,
    method: 'delete'
  })
}

// 查询所有酒店信息列表
export function listAllhotel_info(query) {
  return request({
    url: '/hotel/hotel_info/list-all',
    method: 'get',
    params: query
  })
}

// 查询酒店数量
export function hotelCount() {
  return request({
    url: '/hotel/hotel_info/hotelCount',
    method: 'get'
  })
}
