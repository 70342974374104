<template>
  <div :class="{'has-logo':showLogo}"
       :style="{ backgroundColor: settings.sideTheme === 'theme-dark' ? variables.menuBackground : variables.menuLightBackground }">
    <logo v-if="showLogo" :collapse="isCollapse"/>
    <el-scrollbar :class="settings.sideTheme" wrap-class="scrollbar-wrapper">
      <div id="disFlex" @mouseleave="leaveItem()">
        <div class="leftBox"
             :style="`background-color:${settings.sideTheme === 'theme-dark' ? variables.menuBackground : variables.menuLightBackground}`">
          <div
            v-for="(route, index) in routeList"
            :key="index"
            class="item"
            :class="{ active: activeIndex === index }"
            @click="activeItem(index,route)"
          >
            <svg-icon v-if="route.meta" :icon-class="route.meta && route.meta.icon"/>
            <span v-if="route.name"
                  :style="`color:${settings.sideTheme === 'theme-dark' ? variables.menuColor : variables.menuLightColor}`">
                  {{ route.meta.title }}
                </span>
          </div>
        </div>
        <el-menu
          v-if="activeMenu"
          :default-active="activeMenu"
          :collapse="isCollapse"
          :background-color="settings.sideTheme === 'theme-dark' ? variables.menuBackground : variables.menuLightBackground"
          :text-color="settings.sideTheme === 'theme-dark' ? variables.menuColor : variables.menuLightColor"
          :unique-opened="true"
          :active-text-color="settings.theme"
          :collapse-transition="false"
          mode="vertical"
        >
          <sidebar-item
            v-for="(route, index) in routeItem.children"
            :key="route.path  + index"
            :item="route"
            :base-path="route.path"
          />
        </el-menu>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import Logo from './Logo'
import SidebarItem from './SidebarItem'
import variables from '@/assets/styles/variables.scss'

export default {
  components: {SidebarItem, Logo},
  computed: {
    ...mapState(["settings"]),
    ...mapGetters(["sidebarRouters", "sidebar"]),
    activeMenu() {
      const route = this.routeList[this.activeIndex].children[this.childIndex]
      if (this.activeKey) {
        return this.activeKey
      } else {
        return route.path
      }
    },
    routeItem() {
      let parent = {}
      if (this.hoverIndex) {
        parent = JSON.parse(JSON.stringify(this.routeList[this.hoverIndex]))
      } else {
        parent = JSON.parse(JSON.stringify(this.routeList[this.activeIndex]))
      }
      const children = parent.children
      if (children && children.length > 0) {
        children.forEach(item => {
          if (item.path.indexOf('http') === -1) {
            item.path = `${parent.path}/${item.path}`
          }
        })
      }
      return parent
    },
    routeList() {
      const list = JSON.parse(JSON.stringify(this.sidebarRouters))
      const routeList = list.filter(item => {
        return !item.hidden
      })
      routeList.forEach(item => {
        if (!item.meta) {
          item.name = 'Index'
          item.meta = {name: 'index', title: '首页', icon: 'dashboard'}
        }
      })
      return routeList
    },
    showLogo() {
      return this.$store.state.settings.sidebarLogo;
    },
    variables() {
      return variables;
    },
    isCollapse() {
      return !this.sidebar.opened;
    }
  },
  data() {
    return {
      activeIndex: 0,
      hoverIndex: 0,
      refresh: true,
      activeKey: null,
      childIndex: 0
    }
  },
  created() {
    this.activeKey = this.$route.path
    const pathInfo = this.activeKey.split('/')
    if (pathInfo[1] !== 'index') {
      this.activeIndex = this.routeList.findIndex(r => r.path.replace('/', '') === pathInfo[1])
      this.childIndex = this.routeList[this.activeIndex].children.findIndex(r => r.path === pathInfo[2])
    }
  },
  watch: {
    // 监视路由，获取当前路由给选项卡赋当前选中值
    $route(key) {
      if (this.activeKey !== key.path) {
        this.activeKey = key.path
      }
    },
    activeKey(newVal) {
      if (newVal) {
        const pageList = newVal.split('/')
        let activeIndex = this.routeList.findIndex(item => {
          return '/' + pageList[1] === item.path
        })
        if (activeIndex === -1 && pageList[1] === 'index') {
          activeIndex = 0
        }
        if (activeIndex !== -1) {
          const children = this.routeList[activeIndex].children
          const childIndex = children.findIndex(item => {
            return newVal.includes(item.path)
          })
          this.activeIndex = activeIndex
          this.childIndex = childIndex
        }
      }
    }
  },
  methods: {
    activeItem(index, item) {
      this.childIndex = 0
      this.activeIndex = index
      if (item.path.indexOf('http') !== -1) {
        window.location.href = item.path
      }
    },
    leaveItem() {
      this.hoverIndex = 0
    }
  }
};
</script>
<style lang="scss" scoped>
#disFlex {
  display: flex;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: scroll;
  text-align: left;

  .leftBox {
    min-width: 60px;
    color: rgb(191, 203, 217);
    font-size: 14px;
    background: #1f2d3d;

    .item {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      min-width: 50px !important;
      height: 50px !important;
      color: #fff;

      span {
        margin-top: 5px;
      }
    }

    .active {
      background: #0f7277 !important;
      color: #fff;
    }

    .item:hover {
      cursor: pointer;
      background: #263445;
    }
  }
}

#disFlex::-webkit-scrollbar {
  display: none;
}
</style>
