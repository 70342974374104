var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.bloc &&
    !_vm.travel &&
    !_vm.groupTravel &&
    !_vm.merchant &&
    !_vm.marketing
      ? _c("div", [[_c("index-v2")]], 2)
      : _vm._e(),
    _vm.travel ? _c("div", [[_c("index-travel")]], 2) : _vm._e(),
    _vm.bloc ? _c("div", [[_c("index-bloc")]], 2) : _vm._e(),
    _vm.groupTravel ? _c("div", [[_c("index-group-travel")]], 2) : _vm._e(),
    _vm.merchant || _vm.marketing
      ? _c("div", [[_c("index-merchant")]], 2)
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }