import request from '@/utils/request'

// 查询餐饮信息列表
export function listcatering_info(query) {
  return request({
    url: '/catering/catering_info/list',
    method: 'get',
    params: query
  })
}

// 查询餐饮信息详细
export function getcatering_info(id) {
  return request({
    url: '/catering/catering_info/' + id,
    method: 'get'
  })
}

// 新增餐饮信息
export function addcatering_info(data) {
  return request({
    url: '/catering/catering_info',
    method: 'post',
    data: data
  })
}

// 修改餐饮信息
export function updatecatering_info(data) {
  return request({
    url: '/catering/catering_info',
    method: 'put',
    data: data
  })
}

// 修改餐饮信息
export function updatecateringInfoStatus(data) {
  return request({
    url: '/catering/catering_info/editStatus',
    method: 'post',
    data: data
  })
}

export function updatecateringInfoSort(data) {
  return request({
    url: '/catering/catering_info/editSort',
    method: 'post',
    data: data
  })
}


// 删除餐饮信息
export function delcatering_info(id) {
  return request({
    url: '/catering/catering_info/' + id,
    method: 'delete'
  })
}

// 查询所有餐饮信息列表
export function listAllcatering_info(query) {
  return request({
    url: '/catering/catering_info/list-all',
    method: 'get',
    params: query
  })
}

// 查询餐饮数量
export function cateringCount() {
  return request({
    url: '/catering/catering_info/cateringCount',
    method: 'get'
  })
}
