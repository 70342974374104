<template>
  <div>
    <el-input v-model="searchText" id="tipinput" class="tipinput" placeholder="请输入查询地址"/>
    <div id="container"></div>
    <span>[{{ lng }},{{ lat }}]</span>
  </div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader';

export default {
  name: 'XdyAmap',
  props: {
    lng: {
      type: Number
    },
    lat: {
      type: Number
    },
    addr: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      // 搜索文本
      searchText: null,
    }
  },
  watch: {
    addr() {
      setTimeout(() => {
        const lngLat = new AMap.LngLat(this.lng, this.lat)
        this.marker.setPosition(lngLat)
        this.map.setCenter(lngLat)
      }, 100)
    }
  },
  mounted() {
    //DOM初始化完成进行地图初始化
    this.initMap();
  },
  methods: {
    /** 地图初始化 */
    initMap() {
      window._AMapSecurityConfig = {
        securityJsCode: '017924d0350da83dfe5206bdf24c8d78',
      }
      AMapLoader.load({
        key: "4e247829c99cdea13659589b05f3215c",             // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0",      // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ['AMap.ToolBar', 'AMap.AutoComplete', 'AMap.Geolocation', 'AMap.PlaceSearch', 'AMap.Geocoder'],       // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap) => {
          this.map = new AMap.Map("container", {  //设置地图容器id
            viewMode: "2D",    //是否为3D地图模式
            zoom: 12,           //初始化地图级别
            center: [this.lng || 116.397428, this.lat || 39.90923], //初始化地图中心点位置
          });
          this.initMarker()
          if (!this.lng) {
            this.loadGeolocation()
          }
          this.map.addControl(new AMap.ToolBar());
          this.loadPlaceSearch()
          this.bindClick()
        }
      ).catch(e => {
        console.log(e);
      })
    },
    /** 加载Geolocation插件 */
    loadGeolocation() {
      const geolocation = new AMap.Geolocation({
        'showButton': true,//是否显示定位按钮
        'position': 'RB',//定位按钮的位置
        'offset': [20, 100],//定位按钮距离对应角落的距离
        'showMarker': false,//是否显示定位点
        'showCircle': false,//是否显示定位精度圈
        'needAddress': true, //是否需要将定位结果进行逆地理编码操作
      });
      this.map.addControl(geolocation);
      geolocation.getCurrentPosition()
      geolocation.on("complete", e => {
        const {formattedAddress, position} = e
        this.marker.setPosition(position)
        this.updateValue(position, formattedAddress)
      });
    },
    /** 加载输入提示插件 */
    loadPlaceSearch() {
      const placeSearch = new AMap.PlaceSearch({
        map: this.map,
        pageSize: 1,
      });  //构造地点查询类
      new AMap.AutoComplete({
        input: "tipinput"
      }).on("select", e => {
        const {name, location, adcode} = e.poi
        this.marker.setPosition(location)
        this.updateValue(location, name)

        placeSearch.setCity(adcode);
        placeSearch.search(name, (status, result) => {
          if ('complete' === status && 'OK' === result.info) {
            const poi = result.poiList.pois[0]
            this.marker.setPosition(poi.location)
            this.updateValue(poi.location, poi.name)
          }
          this.map.getAllOverlays('marker').forEach(m => {
            if (!m.getExtData().self) {
              m.remove()
            }
          })
        });  //关键字查询查询
      });//注册监听，当选中某条记录时会触发
    },
    /** 绑定点击事件*/
    bindClick() {
      this.map.on("click", e => {
        this.marker.setPosition(e.lnglat)
        this.updateValue(e.lnglat, '')
        new AMap.Geocoder({
          city: "全国", //城市设为北京，默认：“全国”
          radius: 1000 //范围，默认：500
        }).getAddress(e.lnglat, (status, result) => {
          if (status === 'complete' && result.info === 'OK') {
            if (result && result.regeocode) {
              this.updateValue(e.lnglat, result.regeocode.formattedAddress)
            }
          }
        })
      });
    },
    /** 初始化位置标记 */
    initMarker() {
      this.marker = new AMap.Marker({
        position: [this.lng || 116.397428, this.lat || 39.90923],
        map: this.map,
        extData: {self: true}
      });
    },
    /** 更新值到父组件 */
    updateValue({lng, lat}, formattedAddress) {
      this.$emit('update:lng', lng)
      this.$emit('update:lat', lat)
      this.$emit('update:addr', formattedAddress)
    }
  }
}
</script>

<style lang="scss" scoped>
#container {
  width: 100%;
  height: 400px
}

.tipinput {
  width: 250px;
  display: block;
  z-index: 1;
  top: 50px;
  left: 20px;
}
</style>
