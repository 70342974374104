import request from '@/utils/request'


// 查询产品-分销商分组关联列表（旅行社）
export function listMerGoodsGdistributorTravel(query) {
  return request({
    url: '/merchant/mer-goods-gdistributor/getTravelList',
    method: 'get',
    params: query
  })
}

// 查询产品统计-分销商分组关联列表
export function getTravelCount() {
  return request({
    url: '/merchant/mer-goods-gdistributor/getTravelCount',
    method: 'get'
  })
}

// 查询产品-分销商分组关联列表
export function listMerGoodsGdistributor(query) {
  return request({
    url: '/merchant/mer-goods-gdistributor/list',
    method: 'get',
    params: query
  })
}

// 查询产品-分销商分组关联详细
export function getMerGoodsGdistributor(goodsGdistributorId) {
  return request({
    url: '/merchant/mer-goods-gdistributor/' + goodsGdistributorId,
    method: 'get'
  })
}

// 新增产品-分销商分组关联
export function addMerGoodsGdistributor(data) {
  return request({
    url: '/merchant/mer-goods-gdistributor',
    method: 'post',
    data: data
  })
}

// 修改产品-分销商分组关联
export function updateMerGoodsGdistributor(data) {
  return request({
    url: '/merchant/mer-goods-gdistributor',
    method: 'put',
    data: data
  })
}

// 删除产品-分销商分组关联
export function delMerGoodsGdistributor(goodsGdistributorId) {
  return request({
    url: '/merchant/mer-goods-gdistributor/' + goodsGdistributorId,
    method: 'delete'
  })
}

// 查询所有产品-分销商分组关联列表
export function listAllMerGoodsGdistributor(query) {
  return request({
    url: '/merchant/mer-goods-gdistributor/list-all',
    method: 'get',
    params: query
  })
}


// 根据分销商获取分销产品信息----打包使用
export function getPackGoodsGdistributorByDistributorId(query) {
  return request({
    url: '/merchant/mer-goods-gdistributor/pack-goods-gdistributor-by-distributorId',
    method: 'get',
    params: query
  })
}
