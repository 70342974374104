<template>
  <div class="app-container" style="background-color: #F5F4F6">
    <el-row>
      <el-col :span="24">
        <el-card>
          <el-col :span="6">
            <div class="header-title">数据截止：{{ dateTime }}</div>
          </el-col>
          <el-col :span="18">
            <div style="float: right">
              <el-button type="text" @click="restDate('today')">本日</el-button>
              <el-button type="text" @click="restDate('tomorrow')">明日</el-button>
              <el-button type="text" @click="restDate('week')">本周</el-button>
              <el-button type="text" @click="restDate('month')">本月</el-button>&nbsp&nbsp
              <el-date-picker
                v-model="daterange"
                style="width: 240px"
                value-format="yyyy-MM-dd"
                type="daterange"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              ></el-date-picker>&nbsp&nbsp
              <el-button @click="search">查询</el-button>
              <el-button @click="resetQuery">重置</el-button>
            </div>
          </el-col>
          <el-col :span="24">
      <div style="width: 100%;float: left;" v-loading="loadingOrder">
      <el-row :gutter="15" >
        <el-col style="width: 20% !important;" >
          <div class="titleTwo xsorder">
            <div class="header-top-font">
              订单量
            </div>
            <div class="fontsize">
              {{ orderNum }}
            </div>
          </div>
        </el-col>
        <el-col style="width: 20% !important;">
          <div class="titleOne xsorder">
            <div class="header-top-font">
              销售额
            </div>
            <div class=" fontsize">
              {{ saleMoney }}
            </div>
          </div>
        </el-col>
        <el-col style="width: 20% !important;">
          <div class="titleThree xsorder">
            <div class="header-top-font">
              游客量
            </div>
            <div class=" fontsize">
              {{ personNum }}
            </div>

          </div>
        </el-col>
        <el-col style="width: 20% !important;">
          <div class="titleFour xsorder">
            <div class="header-top-font">
              待结算（奖励费）
            </div>
            <div class="fontsize">
              {{ stayJsMoney }}
            </div>
          </div>
        </el-col>
        <el-col style="width: 20% !important;">
          <div class="titleFour xsorder">
            <div class="header-top-font">
              已结算
            </div>
            <div class="fontsize">
              {{ sentJsMoney }}
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
          </el-col>
        </el-card>
      </el-col>
    </el-row>

      <el-row>
      <el-col :span="24">
        <el-card >
          <div slot="header" class="clearfix">
            <span class="title-border">常用功能</span>
          </div>
          <div>
            <el-row :gutter="65" style="margin-left: 50px">
              <el-col style="width: 20% !important;">
                <img src="../assets/index_images/product_icon.png" class="img-icon"/>
                <div class="img-icon-title">
                <a @click="routerLink('product/combinationProducts/travelTicketList',null)">产品管理</a>
                </div>
              </el-col>
              <el-col style="width: 20% !important;">
                <img src="../assets/index_images/shop_icon.png" class="img-icon"/>
                <div class="img-icon-title">
                <a @click="routerLink('merchant/groupTravel/groupTravel',null)">旅行社管理</a>
                </div>
              </el-col>
              <el-col style="width: 20% !important;">
                <img src="../assets/index_images/log_icon.png" class="img-icon"/>
                <div class="img-icon-title">
                <a @click="routerLink('order/travel-order-list/index',null)">订单管理</a>
                </div>
              </el-col>
              <el-col style="width: 20% !important;">
                <img src="../assets/index_images/refund_icon.png" class="img-icon"/>
                <div class="img-icon-title">
                <a @click="routerLink('groupTravel/advance/settlement',null)">资金结算</a>
                </div>
              </el-col>
              <el-col style="width: 20% !important;">
                <img src="../assets/index_images/inventory_icon.png" class="img-icon"/>
                <div class="img-icon-title">
                  <a @click="routerLink('report/teamTicketChecking',null)">报表管理</a>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-card>
      </el-col>
      </el-row>

    <el-row v-loading="loadingResource">
      <el-col :span="24">
        <el-card >
          <div slot="header" class="clearfix">
            <span class="title-border">资源一览</span>
          </div>
          <div>
            <el-row :gutter="30" style="margin-left: 30px">
              <el-col :span="3">
                <div class="resource-num">{{supplierNum}}</div>
                <div class="resource-title"><a @click="routerLink('merchant/shop/index',null)">供应商</a></div>
              </el-col>
              <el-col :span="3">
                <div class="resource-num">{{scenicAreaNum}}</div>
                <div class="resource-title"><a @click="routerLink('product/combinationProducts/travelTicketList',null)">景区</a></div>
              </el-col>
              <el-col :span="3">
                <div class="resource-num">{{cateringNum}}</div>
                <div class="resource-title"><a @click="routerLink('product/catering/cateringList',null)">餐饮</a></div>
              </el-col>
              <el-col :span="3">
                <div class="resource-num">{{hotelNum}}</div>
                <div class="resource-title"><a @click="routerLink('product/hotel/hotelList',null)">酒店</a></div>
              </el-col>
              <el-col :span="3">
                <div class="resource-num">{{guideNum}}</div>
                <div class="resource-title"><a @click="routerLink('product/guide/guideList',null)">导游</a></div>
              </el-col>
              <el-col :span="3">
                <div class="resource-num">{{carNum}}</div>
                <div class="resource-title"><a @click="routerLink('product/car/carInfoList',null)">车队</a></div>
              </el-col>
              <el-col :span="3">
                <div class="resource-num">{{shoppingNum}}</div>
                <div class="resource-title"><a @click="routerLink('product/shopping/shoppingInfoList',null)">购物点</a></div>
              </el-col>
              <el-col :span="3">
                <div class="resource-num">{{projectNum}}</div>
                <div class="resource-title"><a @click="routerLink('product/project/projectInfoList',null)">自费项目</a></div>
              </el-col>
            </el-row>

          </div>
        </el-card>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :span="6" v-loading="loadingOrderStatus">
        <el-card style="height:230px;">
          <div slot="header" class="clearfix">
            <span class="title-border">待办事项</span>
          </div>
          <div>
            <el-row :gutter="20" style="padding-top: 45px">
              <el-col :span="8">
                <div class="resource-num">{{receivingNum}}</div>
                <div class="resource-title"><a @click="routerLink('order/travel-order-list/index')">已接单</a></div>
              </el-col>
              <el-col :span="8">
                <div class="resource-num">{{arrangeNum}}</div>
                <div class="resource-title"><a @click="routerLink('order/travel-order-list/index')">安排中</a></div>
              </el-col>
              <el-col :span="8">
                <div class="resource-num">{{practicableNum}}</div>
                <div class="resource-title"><a @click="routerLink('order/travel-order-list/index')">已落实</a></div>
              </el-col>
            </el-row>
          </div>
        </el-card>
      </el-col>

      <el-col :span="6" v-loading="loadingStay">
        <el-card style="height: 230px">
          <div slot="header" class="clearfix">
            <span class="title-border">待审核</span>
          </div>
          <div>
            <el-row :gutter="20" style="padding-top: 45px">
              <el-col :span="8">
                <div class="resource-num">{{settleNum}}</div>
                <div class="resource-title"><a @click="routerLink('groupTravel/advance/settlement',null)">结算申请</a></div>
              </el-col>
              <el-col :span="8">
                <div class="resource-num">{{orderCheckNum}}</div>
                <div class="resource-title"><a @click="routerLink('order/order-travelAudit-list')">订单核销申请</a></div>
              </el-col>
              <el-col :span="8">
                <div class="resource-num">{{userMoneyNum}}</div>
                <div class="resource-title"><a @click="routerLink('groupTravel/advance/info',null)">用款申请</a></div>
              </el-col>
            </el-row>
          </div>
        </el-card>
      </el-col>

      <el-col :span="12" v-loading="loadingFlowLog">
        <el-card style="height: 230px">
          <div slot="header" class="clearfix">
            <span class="title-border">流程审核</span>
            <el-button style="float: right; padding: 3px 0" type="text" @click="handleClick('beDoing')">/ 在 办</el-button>
            <el-button style="float: right; padding: 3px 0" type="text" @click="handleClick('commission')">待 办 </el-button>
          </div>
          <div>
            <el-row :gutter="20" v-for=" f in flowOrderVos">
              <el-col :span="16">
                <div><span class="span-title">·</span><a style="font-size: 14px" @click="routerLinkFlow(f.flowType)"> 流程单号：{{f.flowOrderNo}}，申请人：{{f.flowApplyUserName}}</a></div>
              </el-col>
              <el-col :span="8">
                <div style="font-size: 14px">{{f.flowTime}}</div>
              </el-col>
            </el-row>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <el-row v-loading="loadingOrderFailLog">
      <el-col :span="24">
        <el-card >
          <div slot="header" class="clearfix">
            <span class="title-border">异常订单日志</span>
          </div>
          <a @click="routerLink('order/order-fail-log-list')">
            <el-table v-loading="loadingOrderFailLog" :data="orderFailLogs">
              <el-table-column label="主键" align="center" prop="orderFailLogId" />
              <el-table-column label="订单号" align="center" prop="orderForeignNo" />
              <el-table-column label="类型" align="center" prop="type">
                <template slot-scope="scope">
                  <dict-tag :options="dict.type.order_fail_log_type" :value="scope.row.type"/>
                </template>
              </el-table-column>
              <el-table-column label="状态" align="center" prop="status">
                <template slot-scope="scope">
                  <dict-tag :options="dict.type.order_fail_log_status" :value="scope.row.status"/>
                </template>
              </el-table-column>
              <el-table-column label="描述" align="center" prop="description" />
              <el-table-column label="创建日期" align="center" prop="createDate" width="180">
                <template slot-scope="scope">
                  <span>{{ parseTime(scope.row.createDate, '{y}-{m}-{d}') }}</span>
                </template>
              </el-table-column>
            </el-table>
          </a>
        </el-card>
      </el-col>
    </el-row>


    <el-row :gutter="20">
      <el-col :span="12" v-loading="loadingSale">
    <el-card>
      <div slot="header" class="clearfix">
        <span class="title-border">销售分析</span>
        <el-button style="float: right; padding: 3px 0" type="text" @click="resetQuery">/ 重 置</el-button>
        <el-button style="float: right; padding: 3px 0" type="text" @click="saleAnalyse">查 询 </el-button>
      </div>
    <div class="maps">
      <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" style="padding-left: 60px">
        <el-form-item prop="dateType">
<!--          <el-select v-model="queryParams.dateType" placeholder="请选择日期类型" clearable>-->
<!--            <el-option-->
<!--              v-for="dict in dict.type.sale_analyse_type"-->
<!--              :key="dict.value"-->
<!--              :label="dict.label"-->
<!--              :value="dict.value"-->
<!--            />-->
<!--          </el-select>-->
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="daterange"
            style="width: 240px"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <div id="productEchart" class="maps-echart"></div>
    </div>
    </el-card>
      </el-col>
      <el-col :span="12" v-loading="loadingMaps">
        <el-card >
          <div slot="header" class="clearfix">
            <span class="title-border">客源地分析</span>
          </div>
    <div class="maps">
      <div class="zEcharts">
        <span class="person-count" style="padding-left: 30px;font-size: 14px">人数统计</span>
        <div class="map-container" id="myEcharts" ref="myEchart"
             style="margin-left: 10%;width:450px; height: 450px;"></div>
      </div>
    </div>
        </el-card>
      </el-col>
      </el-row>

    <el-row :gutter="10" >
      <el-col :span="24">
        <el-card class="future-card">
          <div id="futureEchart" class="future-echart"></div>
        </el-card>
      </el-col>
    </el-row>

    <el-row :gutter="10" >
      <el-col :span="24">
        <el-card class="allYear-card">
          <div id="allYearEchart" class="allYear-echart"></div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import 'echarts/map/js/china.js'
import dayjs from "dayjs";
import {
  orderCount,orderStatusCount,areaCount,getFutureAndAllYearCountTravel
} from "@/api/order/order-travel-declare-order";
import {carCount} from"@/api/car/car_info"
import {cateringCount} from '@/api/catering/catering_info'
import {guideCount} from '@/api/guide/guide'
import {hotelCount} from '@/api/hotel/hotel_info'
import {shopCount} from '@/api/merchant/shop'
import {projectCount} from '@/api/project/info'
import {shoppingCount} from '@/api/shopping/info'
import {getTravelCount} from '@/api/merchant/merGoodsGdistributor'
import {auditProcessCount} from '@/api/group-travel/orderSettleRecord'
import {countTravelOrderAuditId} from '@/api/order/order-travel-audit-log'
import {capPaymentAuditCount} from '@/api/paymentApply/audit'
import {beDoing,commission} from '@/api/paymentApply/info'
import {saleAnalyse} from '@/api/order/order-travel-declare-order'
import {areaListByPid} from "@/api/merchant/merArea";
import {listCount} from '@/api/order/order-fail-log'

export default {
  name: "index",
  dicts: ['sale_analyse_type','order_fail_log_type', 'order_fail_log_status'],
  data() {
    return {
      loadingOrder:false,
      loadingResource:false,
      loadingOrderStatus:false,
      loadingStay:false,
      loadingFlowLog:false,
      loadingOrderFailLog:false,
      loadingSale:false,
      loadingMaps:false,
      // 查询条件
      queryParams:{
         dateType:'1',
         startDate:dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
         endDate:dayjs().format('YYYY-MM-DD'),
      },
      // 时间区间
      daterange:[
        dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
        dayjs().format('YYYY-MM-DD')
      ],
      // 当前时间（数据截止时间）
      dateTime:dayjs().format('YYYY-MM-DD HH:mm:ss'),
      // 版本号
      version: "2.4.0",
      // 第一排统计数据
      orderNum: null,
      saleMoney: null,
      stayJsMoney: null,
      sentJsMoney: null,
      personNum: null,
      //供应商数量
      supplierNum:0,
      //景区数量
      scenicAreaNum:0,
      //餐饮数量
      cateringNum:0,
      //酒店数量
      hotelNum:0,
      //导游数量
      guideNum:0,
      //车队数量
      carNum:0,
      //购物点数量
      shoppingNum:0,
      //自费项目数量
      projectNum:0,
      //已接单
      receivingNum:0,
      //安排在
      arrangeNum:0,
      //已落实
      practicableNum:0,
      //结算申请
      settleNum:0,
      //订单核销申请
      orderCheckNum:0,
      //用款申请
      userMoneyNum:0,
      //在办/待办事项信息
      flowOrderVos:[],
      //异常订单日志
      orderFailLogs:[],
      //中国地图参数
      optionMaps: {
        tooltip: {
          trigger: "item",
        },
        // title: {
        //   show: true, //是否显示
        //   text: "客源地分析",
        // },
        legend: {
          orient: "horizontal", //图例的排列方向
          textStyle: { color: "#3acc1c" },
          x: "left", //图例的位置
          y: "-20000000000000",
          data: []
        },
        visualMap: {
          //颜色的设置  dataRange
          textStyle: { color: "#171d1a" },
          x: "left",
          y: "bottom",
          splitList: [
            { start: 500, color: "#1221e1" },
            { start: 300, end: 500, color: "#3847e1" },
            { start: 11, end: 300, color: "#606de1" },
            { start: 1, end: 10, color: "#99a2e1" }
          ],
          show: true,
          // text:['高','低'],// 文本，默认为数值文本
          color: ['rgb(50,88,124)']
          // color: [
          //     "#5475f5",
          //     "#9feaa5",
          //     "#3FA7FF",
          //     "#66E0E3",
          //     "#FFDC5E",
          //     "#9fb5ea"
          // ]
        },
        roamController: {//控制地图的上下左右放大缩小
          show: true,
          x: 'right',
          mapTypeControl: {
            'china': true
          }
        },
        series: [
          {
            data: [],
            top: "30", //组件距离容器的距离
            name: "人数",
            type: "map",
            mapType: "china",
            zoom: 1.22,//地图大小
            roam: false, //是否开启鼠标缩放和平移漫游
            itemStyle: {
              //地图区域的多边形 图形样式
              normal: {
                //是图形在默认状态下的样式
                label: {
                  show: true,
                  textStyle: { color: "rgb(0, 0, 0)" }//地图text样式
                },
              },
              emphasis: {
                //是图形在高亮状态下的样式,比如在鼠标悬浮或者图例联动高亮时
                label: { show: true }
              }
            },
          }
        ]
      },
      maps:[],
      optionProduct:{
        tooltip: {
          trigger: "item",
        },
        // title: {
        //   show: true, //是否显示
        //   text: "销售分析",
        // },
        grid: {containLabel: false},
        xAxis: {
          name: '店铺',
          data:[]
        },
        yAxis: {
          name: '游玩人数',
        },
        series: [
          {
            type: 'line',
            stack: 'Total',
            smooth: false,
            data: [],
            itemStyle: {
              normal: {
                lineStyle:{color:'#00a0e9'}
              }
            },
          },
        ]
      },
      //未来7天分析折线图
      futureOption: {
        tooltip: {
          trigger: 'item'
        },
        title: {
          show: true, //是否显示
          text: "未来7天客流分析",
          left: 'center',
        },
        grid: { containLabel: false },
        xAxis: {
          name:'日期',
          data: ['2024-01-17', '2024-01-18', '2024-01-19', '2024-01-20', '2024-01-21', '2024-01-22','2024-01-23'],
        },
        yAxis: {
          name:'人次',
        },
        series: [
          {
            type: 'line',
            stack: 'Total',
            smooth: false,
            data: [10, 15, 30, 20, 5, 12,30],
            itemStyle: {
              normal: {
                lineStyle:{color:'#00a0e9'}
              }
            },
          }
        ]
      },
      //全年接待游客量走势折线图
      allYearOption: {
        tooltip: {
          trigger: 'item'
        },
        title: {
          show: true, //是否显示
          text: "全年接待游客量走势",
          left: 'center',
        },
        grid: { containLabel: false },
        xAxis: {
          name:'月份',
          data: ['2024-01', '2024-02', '2024-03', '2024-04', '2024-05', '2024-06','2024-07','2024-08','2024-09','2024-10','2024-11','2024-12'],
        },
        yAxis: {
          name:'人次',
        },
        series: [
          {
            type: 'line',
            stack: 'Total',
            smooth: false,
            data: [10, 15, 30, 20, 5, 12,30,50,60,20,100,10],
            itemStyle: {
              normal: {
                lineStyle:{color:'#00a0e9'}
              }
            },
          }
        ]
      },
    }
  },
  mounted() {
    this.orderCount();
    this.resourceCount();
    this.orderStatusCount();
    this.stayAudit();
    this.saleAnalyse();
    this.getOrderFailLogs();
    this.areaCountNum();
    this.handleClick('beDoing');
    this.bottomPersonCount();
  },
  watch: {

  },
  methods: {
    routerLink(url,param){
      if(param!=null){
        this.$router.push({path:url,query:param});
      }else{
        this.$router.push({path:url});
      }
    },

    routerLinkFlow(flowType){
      if(flowType==2){
        this.$router.push({path:'groupTravel/advance/info'});
      }else{
        this.$router.push({path:'groupTravel/advance/settlement'});
      }
    },

    handleClick(tabName){
      this.loadingFlowLog = true;
      if(tabName=="beDoing"){
        beDoing().then(response => {
          this.flowOrderVos=response.data;
          this.loadingFlowLog = false;
        })
      }else{
        commission().then(response => {
          this.flowOrderVos=response.data;
          this.loadingFlowLog = false;
        })
      }
    },

    //销售分析
    saleAnalyse(){
      this.loadingSale = true;
      let productEchart = this.$echarts.init(document.getElementById('productEchart'))
      if(this.daterange==null || this.daterange=='' || this.daterange.length==0){
        this.$modal.msgError("查询日期必选！");
        this.loadingSale = false;
        return
      }
      this.queryParams.startDate=this.daterange[0];
      this.queryParams.endDate=this.daterange[1];
      saleAnalyse(this.queryParams).then(response => {
        this.optionProduct.xAxis.data=response.data.shopNameList;
        this.optionProduct.series[0].data=response.data.personNumList;
        productEchart.setOption(this.optionProduct);
        this.loadingSale = false;
      })
    },

    search(){
      if(this.daterange==null || this.daterange==[] || this.daterange==''){
        this.$modal.msgError("日期必选");
        return;
      }
      this.queryParams = {
        startDate: this.daterange[0],
        endDate: this.daterange[1]
      }
      this.orderCount();
    },

    //日期更改
    restDate(type){
      var startDate = null;
      var endDate = null;
      if(type=='today'){
        startDate =  dayjs().format('YYYY-MM-DD');
        endDate = dayjs().format('YYYY-MM-DD');
      }else if(type=='tomorrow'){
        startDate =  dayjs().add(1, 'day').format('YYYY-MM-DD');
        endDate = dayjs().add(1, 'day').format('YYYY-MM-DD');
      }else if(type=='week'){
        startDate =  dayjs().startOf('week').add(1, 'day').format('YYYY-MM-DD');
        endDate = dayjs().endOf('week').add(1, 'day').format('YYYY-MM-DD');
      }else{
        startDate =  dayjs().startOf('month').format('YYYY-MM-DD') ;
        endDate = dayjs().endOf('month').format('YYYY-MM-DD');
      }
      this.daterange = [
        startDate,
        endDate
      ]
    },

    resetQuery(){
      var startDate = dayjs().subtract(1, 'month').format('YYYY-MM-DD');
      var endDate = dayjs().format('YYYY-MM-DD');
      this.queryParams = {
          dateType:'1',
          startDate:startDate,
          endDate:endDate,
      };
      this.daterange=[
        dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
        dayjs().format('YYYY-MM-DD')
      ];
      this.saleAnalyse();
    },

    //顶部订单统计
    orderCount(){
      this.loadingOrder = true;
      orderCount(this.queryParams).then(response => {
        this.orderNum = response.data.orderNum>=0?response.data.orderNum:0;
        this.saleMoney = response.data.saleMoney;
        this.stayJsMoney = response.data.stayJsMoney;
        this.personNum = response.data.personNum>=0?response.data.personNum:0;
        this.sentJsMoney = response.data.sentJsMoney;
        this.loadingOrder = false;
      }).catch(res => {
        this.loadingOrder = false;
      })
    },

    //代办事项
    orderStatusCount(){
      this.loadingOrderStatus = true;
      orderStatusCount().then(response => {
        this.receivingNum = response.data.receivingNum;
        this.arrangeNum = response.data.arrangeNum;
        this.practicableNum = response.data.practicableNum;
        this.loadingOrderStatus = false;
      }).catch(res => {
        this.loadingOrderStatus = false;
      })
    },

    /** 查询省级区域列表 */
    getProvinceList() {
      areaListByPid(0).then(response => {
        this.provinceList = response.data;
      });
    },

    //资源一览
    resourceCount(){
      this.loadingResource = true;
      carCount().then(response => {
        this.carNum=response.data!=null?response.data:0;
      })
      cateringCount().then(response => {
        this.cateringNum=response.data!=null?response.data:0;
      })
      guideCount().then(response => {
        this.guideNum=response.data!=null?response.data:0;
      })
      hotelCount().then(response => {
        this.hotelNum=response.data!=null?response.data:0;
      })
      shopCount().then(response => {
        this.supplierNum=response.data!=null?response.data:0;
      })
      projectCount().then(response => {
        this.projectNum=response.data!=null?response.data:0;
      })
      shoppingCount().then(response => {
        this.shoppingNum=response.data!=null?response.data:0;
      })
      getTravelCount().then(response => {
        this.scenicAreaNum=response.data!=null?response.data:0;
        this.loadingResource = false;
      }).catch(res => {
        this.loadingResource = false;
      })
    },

    //待审核
    stayAudit(){
        this.loadingStay = true;

        auditProcessCount().then(response => {
          this.settleNum=response.data;
          this.loadingStay = false;
        }).catch(res => {
          this.loadingStay = false;
        })

        countTravelOrderAuditId().then(response => {
          this.orderCheckNum=response.data;
          this.loadingStay = false;
        }).catch(res => {
          this.loadingStay = false;
        })

        capPaymentAuditCount().then(response => {
          this.userMoneyNum=response.data;
          this.loadingStay = false;
        }).catch(res => {
          this.loadingStay = false;
        })
    },

    //异常订单日志
    getOrderFailLogs(){
      this.loadingOrderFailLog=true;
        listCount().then(response=>{
          this.orderFailLogs=response.data;
          this.loadingOrderFailLog=false;
        })
    },

    areaCountNum(){
      this.loadingMaps = true;
      areaListByPid(0).then(response => {
        let area = response.data;
        areaCount().then(response => {
          let areaNum = response.data;
          let arr = [];
          areaNum.forEach(item=>{
            area.forEach(a=>{
              if(item.areaCode == a.id.toString().substr(0,2)){
                arr.push({name:a.name,value:item.num})
              }
            })
          })
         this.initEcharts(arr);
        }).catch(res => {
          this.loadingMaps = false;
        });
      }).catch(res => {
        this.loadingMaps = false;
      });
    },

    initEcharts(arry) {
      let mapDiv = document.getElementById('myEcharts')
      let chinaMapChart = this.$echarts.init(mapDiv)
      this.optionMaps.series[0].data = arry;
      chinaMapChart.setOption(this.optionMaps,true);
      this.loadingMaps = false;
    },

    bottomPersonCount(){
      getFutureAndAllYearCountTravel().then(response =>{
        let futureEchart = this.$echarts.init(document.getElementById('futureEchart'));
        this.futureOption.xAxis.data=response.data.futureDates;
        this.futureOption.series[0].data=response.data.futureNums;
        futureEchart.setOption(this.futureOption);
        let allYearEchart = this.$echarts.init(document.getElementById('allYearEchart'));
        this.allYearOption.xAxis.data=response.data.allYearPersonDates;
        this.allYearOption.series[0].data=response.data.allYearPersonNums;
        allYearEchart.setOption(this.allYearOption);
      })
    },


  },
};
</script>

<style scoped lang="scss">

.zEcharts {
  width: 100%;
  height: 100%;
}

.figure {
  height: 250px;
  width: 50%;
  float: left;
  margin-top: 10%;
}

.maps {
  height: 480px;
  width: 100%;
  float: left;
  margin-top: 10px;
}

.maps-echart{
  height: 430px;
  width: 100%;
}



.lattice {
  float: left;
  line-height: 50px;
  width: 152px;
  margin: 0px 22px;
  height: 50px;
  color: #fff;
  align-content: center;
  text-align: center;
  background-color: #368E8E;
}
.titleOne{
  background-image: url("../assets/index_images/groupTravel/todaySaleNum.png");
}
.titleTwo{
  background-image: url("../assets/index_images/groupTravel/saleNum.png");
}
.titleThree{
  background-image: url("../assets/index_images/groupTravel/tomorrowSaleNum.png");
}
.titleFour{
  background-image: url("../assets/index_images/groupTravel/stay_js.png");
}
.titleFive{
  background-image: url("../assets/index_images/groupTravel/sent_js.png");
}
.xsorder{
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 130px;
  width: 95%;
  color: #ffffff;
}
.xsorder span {
  margin-left: 12%;
}

.header-top-font{
   padding-top: 10%;
   padding-left: 25%;
}

.fontsize{
  font-size: 40px!important;
  padding-top: 7%;
  padding-left: 10%;
}

.img-icon{
  width: 50px;
  height: 50px;
}

.img-icon-title{
   font-size: 18px;
   margin-left: 65px;
   margin-top: -40px;
}

.span-title{
  font-size: 20px;
  color: #F39800;
  font-weight: bold;
}

.title-border{
  border-left: 5px solid #1890FF;
  padding-left: 10px;
  font-weight: bold;
}
.person-count{
  color: #9AA6B2;
}
.el-row {
  margin-bottom: 20px;
}
.header-title{
  font-size: 14px;
  color: #9AA6B2;
  padding: 0px 0px 20px 0px;
}
.resource-num{
  font-size: 20px;
  font-weight: bold;
}
.resource-title{
  font-size: 12px;
  margin-top: 20px;
}
.future-card{
  height: 450px;
  width: 100%;
}
.future-echart{
  height: 400px;
  width: 100%;
}
.allYear-card{
  height: 450px;
  width: 100%;
}
.allYear-echart{
  height: 400px;
  width: 100%;
}
.el-card{
  border-radius: 15px;
}
</style>

