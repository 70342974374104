var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container",
      staticStyle: { "background-color": "#F5F4F6" },
    },
    [
      _c(
        "el-row",
        [
          _c(
            "el-card",
            [
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "header-title" }, [
                  _vm._v("数据截止：" + _vm._s(_vm.dateTime)),
                ]),
              ]),
              _c("el-col", { attrs: { span: 18 } }, [
                _c(
                  "div",
                  { staticStyle: { float: "right" } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.restDate("today")
                          },
                        },
                      },
                      [_vm._v("本日")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.restDate("tomorrow")
                          },
                        },
                      },
                      [_vm._v("明日")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.restDate("week")
                          },
                        },
                      },
                      [_vm._v("本周")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.restDate("month")
                          },
                        },
                      },
                      [_vm._v("本月")]
                    ),
                    _vm._v(" "),
                    _c("el-date-picker", {
                      staticStyle: { width: "240px" },
                      attrs: {
                        "value-format": "yyyy-MM-dd",
                        type: "daterange",
                        "range-separator": "-",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期",
                      },
                      model: {
                        value: _vm.daterange,
                        callback: function ($$v) {
                          _vm.daterange = $$v
                        },
                        expression: "daterange",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-button", { on: { click: _vm.search } }, [
                      _vm._v("查询"),
                    ]),
                    _c("el-button", { on: { click: _vm.resetQuery } }, [
                      _vm._v("重置"),
                    ]),
                  ],
                  1
                ),
              ]),
              _c("el-col", { attrs: { span: 24 } }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loadingOrder,
                        expression: "loadingOrder",
                      },
                    ],
                    staticStyle: { width: "100%", float: "left" },
                  },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { staticStyle: { width: "20% !important" } },
                          [
                            _c("div", { staticClass: "titleOne xsorder" }, [
                              _c("div", { staticClass: "header-top-font" }, [
                                _vm._v(" 订单量 "),
                              ]),
                              _c("div", { staticClass: " fontsize" }, [
                                _vm._v(" " + _vm._s(_vm.orderNum) + " "),
                              ]),
                            ]),
                          ]
                        ),
                        _c(
                          "el-col",
                          { staticStyle: { width: "20% !important" } },
                          [
                            _c("div", { staticClass: "titleTwo xsorder" }, [
                              _c("div", { staticClass: "header-top-font" }, [
                                _vm._v(" 销售量 "),
                              ]),
                              _c("div", { staticClass: "fontsize" }, [
                                _vm._v(" " + _vm._s(_vm.saleMoney) + " "),
                              ]),
                            ]),
                          ]
                        ),
                        _c(
                          "el-col",
                          { staticStyle: { width: "20% !important" } },
                          [
                            _c("div", { staticClass: "titleThree xsorder" }, [
                              _c("div", { staticClass: "header-top-font" }, [
                                _vm._v(" 游客量 "),
                              ]),
                              _c("div", { staticClass: " fontsize" }, [
                                _vm._v(" " + _vm._s(_vm.personNum) + " "),
                              ]),
                            ]),
                          ]
                        ),
                        _c(
                          "el-col",
                          { staticStyle: { width: "20% !important" } },
                          [
                            _c("div", { staticClass: "titleFour xsorder" }, [
                              _c("div", { staticClass: "header-top-font" }, [
                                _vm._v(" 待结算（奖励费） "),
                              ]),
                              _c("div", { staticClass: "fontsize" }, [
                                _vm._v(" " + _vm._s(_vm.stayJsMoney) + " "),
                              ]),
                            ]),
                          ]
                        ),
                        _c(
                          "el-col",
                          { staticStyle: { width: "20% !important" } },
                          [
                            _c("div", { staticClass: "titleFive xsorder" }, [
                              _c("div", { staticClass: "header-top-font" }, [
                                _vm._v(" 已结算 "),
                              ]),
                              _c("div", { staticClass: "fontsize" }, [
                                _vm._v(" " + _vm._s(_vm.sentJsMoney) + " "),
                              ]),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c("el-card", [
                _c(
                  "div",
                  {
                    staticClass: "clearfix",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [
                    _c("span", { staticClass: "title-border" }, [
                      _vm._v("常用功能"),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  [
                    _c(
                      "el-row",
                      {
                        staticStyle: { "margin-left": "50px" },
                        attrs: { gutter: 65 },
                      },
                      [
                        _c("el-col", { attrs: { span: 6 } }, [
                          _c("img", {
                            staticClass: "img-icon",
                            attrs: {
                              src: require("../assets/index_images/product_icon.png"),
                            },
                          }),
                          _c("div", { staticClass: "img-icon-title" }, [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.routerLink(
                                      "product/ticket/index",
                                      null
                                    )
                                  },
                                },
                              },
                              [_vm._v("产品管理")]
                            ),
                          ]),
                        ]),
                        _c("el-col", { attrs: { span: 6 } }, [
                          _c("img", {
                            staticClass: "img-icon",
                            attrs: {
                              src: require("../assets/index_images/log_icon.png"),
                            },
                          }),
                          _c("div", { staticClass: "img-icon-title" }, [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.routerLink(
                                      "order/group-travel-order-list/ticket-index",
                                      null
                                    )
                                  },
                                },
                              },
                              [_vm._v("订单管理")]
                            ),
                          ]),
                        ]),
                        _c("el-col", { attrs: { span: 6 } }, [
                          _c("img", {
                            staticClass: "img-icon",
                            attrs: {
                              src: require("../assets/index_images/refund_icon.png"),
                            },
                          }),
                          _c("div", { staticClass: "img-icon-title" }, [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.routerLink(
                                      "groupTravel/advance/settlement",
                                      null
                                    )
                                  },
                                },
                              },
                              [_vm._v("资金管理")]
                            ),
                          ]),
                        ]),
                        _c("el-col", { attrs: { span: 6 } }, [
                          _c("img", {
                            staticClass: "img-icon",
                            attrs: {
                              src: require("../assets/index_images/inventory_icon.png"),
                            },
                          }),
                          _c("div", { staticClass: "img-icon-title" }, [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.routerLink(
                                      "report/teamTicketChecking",
                                      null
                                    )
                                  },
                                },
                              },
                              [_vm._v("报表查询")]
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadingOrderStatus,
                  expression: "loadingOrderStatus",
                },
              ],
              attrs: { span: 6 },
            },
            [
              _c("el-card", { staticStyle: { height: "230px" } }, [
                _c(
                  "div",
                  {
                    staticClass: "clearfix",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [
                    _c("span", { staticClass: "title-border" }, [
                      _vm._v("待办事项"),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  [
                    _c(
                      "el-row",
                      {
                        staticStyle: { "padding-top": "45px" },
                        attrs: { gutter: 20 },
                      },
                      [
                        _c("el-col", { attrs: { span: 8 } }, [
                          _c("div", { staticClass: "resource-num" }, [
                            _vm._v(_vm._s(_vm.receivingNum)),
                          ]),
                          _c("div", { staticClass: "resource-title" }, [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.routerLink(
                                      "order/travel-order-list/index"
                                    )
                                  },
                                },
                              },
                              [_vm._v("已接单")]
                            ),
                          ]),
                        ]),
                        _c("el-col", { attrs: { span: 8 } }, [
                          _c("div", { staticClass: "resource-num" }, [
                            _vm._v(_vm._s(_vm.arrangeNum)),
                          ]),
                          _c("div", { staticClass: "resource-title" }, [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.routerLink(
                                      "order/travel-order-list/index"
                                    )
                                  },
                                },
                              },
                              [_vm._v("安排中")]
                            ),
                          ]),
                        ]),
                        _c("el-col", { attrs: { span: 8 } }, [
                          _c("div", { staticClass: "resource-num" }, [
                            _vm._v(_vm._s(_vm.practicableNum)),
                          ]),
                          _c("div", { staticClass: "resource-title" }, [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.routerLink(
                                      "order/travel-order-list/index"
                                    )
                                  },
                                },
                              },
                              [_vm._v("已落实")]
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-col",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadingStay,
                  expression: "loadingStay",
                },
              ],
              attrs: { span: 6 },
            },
            [
              _c("el-card", { staticStyle: { height: "230px" } }, [
                _c(
                  "div",
                  {
                    staticClass: "clearfix",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [
                    _c("span", { staticClass: "title-border" }, [
                      _vm._v("待审核"),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  [
                    _c(
                      "el-row",
                      {
                        staticStyle: { "padding-top": "45px" },
                        attrs: { gutter: 20 },
                      },
                      [
                        _c("el-col", { attrs: { span: 8 } }, [
                          _c("div", { staticClass: "resource-num" }, [
                            _vm._v(_vm._s(_vm.settleNum)),
                          ]),
                          _c("div", { staticClass: "resource-title" }, [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.routerLink(
                                      "groupTravel/advance/settlement",
                                      null
                                    )
                                  },
                                },
                              },
                              [_vm._v("结算申请")]
                            ),
                          ]),
                        ]),
                        _c("el-col", { attrs: { span: 8 } }, [
                          _c("div", { staticClass: "resource-num" }, [
                            _vm._v(_vm._s(_vm.orderCheckNum)),
                          ]),
                          _c("div", { staticClass: "resource-title" }, [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.routerLink(
                                      "order/order-travelAudit-list"
                                    )
                                  },
                                },
                              },
                              [_vm._v("订单核销申请")]
                            ),
                          ]),
                        ]),
                        _c("el-col", { attrs: { span: 8 } }, [
                          _c("div", { staticClass: "resource-num" }, [
                            _vm._v(_vm._s(_vm.userMoneyNum)),
                          ]),
                          _c("div", { staticClass: "resource-title" }, [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.routerLink(
                                      "groupTravel/advance/info",
                                      null
                                    )
                                  },
                                },
                              },
                              [_vm._v("用款申请")]
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-col",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadingFlowLog,
                  expression: "loadingFlowLog",
                },
              ],
              attrs: { span: 12 },
            },
            [
              _c("el-card", { staticStyle: { height: "230px" } }, [
                _c(
                  "div",
                  {
                    staticClass: "clearfix",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [
                    _c("span", { staticClass: "title-border" }, [
                      _vm._v("流程审核"),
                    ]),
                    _c(
                      "el-button",
                      {
                        staticStyle: { float: "right", padding: "3px 0" },
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleClick("beDoing")
                          },
                        },
                      },
                      [_vm._v("/ 在 办 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticStyle: { float: "right", padding: "3px 0" },
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleClick("commission")
                          },
                        },
                      },
                      [_vm._v("待 办 ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  _vm._l(_vm.flowOrderVos, function (f) {
                    return _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c("el-col", { attrs: { span: 16 } }, [
                          _c("div", [
                            _c("span", { staticClass: "span-title" }, [
                              _vm._v("·"),
                            ]),
                            _c(
                              "a",
                              {
                                staticStyle: { "font-size": "14px" },
                                on: {
                                  click: function ($event) {
                                    return _vm.routerLinkFlow(f.flowType)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " 流程单号：" +
                                    _vm._s(f.flowOrderNo) +
                                    "，申请人：" +
                                    _vm._s(f.flowApplyUserName)
                                ),
                              ]
                            ),
                          ]),
                        ]),
                        _c("el-col", { attrs: { span: 8 } }, [
                          _c("div", { staticStyle: { "font-size": "14px" } }, [
                            _vm._v(_vm._s(f.flowTime)),
                          ]),
                        ]),
                      ],
                      1
                    )
                  }),
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadingSale,
                  expression: "loadingSale",
                },
              ],
              attrs: { span: 12 },
            },
            [
              _c("el-card", [
                _c(
                  "div",
                  {
                    staticClass: "clearfix",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [
                    _c("span", { staticClass: "title-border" }, [
                      _vm._v("销售分析"),
                    ]),
                    _c(
                      "el-button",
                      {
                        staticStyle: { float: "right", padding: "3px 0" },
                        attrs: { type: "text" },
                        on: { click: _vm.resetQuery },
                      },
                      [_vm._v("/ 重 置")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticStyle: { float: "right", padding: "3px 0" },
                        attrs: { type: "text" },
                        on: { click: _vm.saleAnalyse },
                      },
                      [_vm._v("查 询")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "maps" },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "queryForm",
                        staticStyle: { "padding-left": "60px" },
                        attrs: {
                          model: _vm.queryParams,
                          size: "small",
                          inline: true,
                        },
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { prop: "dateType" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: "请选择日期类型",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.queryParams.dateType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.queryParams, "dateType", $$v)
                                  },
                                  expression: "queryParams.dateType",
                                },
                              },
                              _vm._l(
                                _vm.dict.type.sale_analyse_type,
                                function (dict) {
                                  return _c("el-option", {
                                    key: dict.value,
                                    attrs: {
                                      label: dict.label,
                                      value: dict.value,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          [
                            _c("el-date-picker", {
                              staticStyle: { width: "240px" },
                              attrs: {
                                "value-format": "yyyy-MM-dd",
                                type: "daterange",
                                "range-separator": "-",
                                "start-placeholder": "开始日期",
                                "end-placeholder": "结束日期",
                              },
                              model: {
                                value: _vm.daterange,
                                callback: function ($$v) {
                                  _vm.daterange = $$v
                                },
                                expression: "daterange",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("div", {
                      staticClass: "maps-echart",
                      attrs: { id: "productEchart" },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-col",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadingMaps,
                  expression: "loadingMaps",
                },
              ],
              attrs: { span: 12 },
            },
            [
              _c("el-card", [
                _c(
                  "div",
                  {
                    staticClass: "clearfix",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [
                    _c("span", { staticClass: "title-border" }, [
                      _vm._v("客源地分析"),
                    ]),
                  ]
                ),
                _c("div", { staticClass: "maps" }, [
                  _c("div", { staticClass: "zEcharts" }, [
                    _c(
                      "span",
                      {
                        staticClass: "person-count",
                        staticStyle: {
                          "padding-left": "30px",
                          "font-size": "14px",
                        },
                      },
                      [_vm._v("人数统计")]
                    ),
                    _c("div", {
                      ref: "myEchart",
                      staticClass: "map-container",
                      staticStyle: {
                        "margin-left": "10%",
                        width: "450px",
                        height: "450px",
                      },
                      attrs: { id: "myEcharts" },
                    }),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 10 } },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c("el-card", { staticClass: "future-card" }, [
                _c("div", {
                  staticClass: "future-echart",
                  attrs: { id: "futureEchart" },
                }),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 10 } },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c("el-card", { staticClass: "allYear-card" }, [
                _c("div", {
                  staticClass: "allYear-echart",
                  attrs: { id: "allYearEchart" },
                }),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }