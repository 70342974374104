import request from '@/utils/request'

// 查询申报订单审核日志列表
export function listtravelauditlog(query) {
  return request({
    url: '/order/travel-audit-log/list',
    method: 'get',
    params: query
  })
}

// 查询申报订单审核日志详细
export function gettravelauditlog(travelOrderAuditLogId) {
  return request({
    url: '/order/travel-audit-log/' + travelOrderAuditLogId,
    method: 'get'
  })
}

// 新增申报订单审核日志
export function addtravelauditlog(data) {
  return request({
    url: '/order/travel-audit-log',
    method: 'post',
    data: data
  })
}


// 查询所有申报订单审核日志列表
export function listAlltravelauditlog(query) {
  return request({
    url: '/order/travel-audit-log/list-all',
    method: 'get',
    params: query
  })
}

// 申报订单号统计
export function countTravelOrderAuditId() {
  return request({
    url: '/order/travel-audit-log/countTravelOrderAuditId',
    method: 'get'
  })
}
