import request from '@/utils/request'

// 查询异常订单日志列表
export function list(query) {
  return request({
    url: '/order/order-fail-log/list',
    method: 'get',
    params: query
  })
}

// 查询异常订单日志详细
export function getInfo(orderFailLogId) {
  return request({
    url: '/order/order-fail-log/' + orderFailLogId,
    method: 'get'
  })
}

// 新增异常订单日志
export function add(data) {
  return request({
    url: '/order/order-fail-log',
    method: 'post',
    data: data
  })
}

// 修改异常订单日志
export function update(data) {
  return request({
    url: '/order/order-fail-log',
    method: 'put',
    data: data
  })
}

// 删除异常订单日志
export function del(orderFailLogId) {
  return request({
    url: '/order/order-fail-log/' + orderFailLogId,
    method: 'delete'
  })
}

// 查询所有异常订单日志列表
export function listAll(query) {
  return request({
    url: '/order/order-fail-log/list-all',
    method: 'get',
    params: query
  })
}

// 查询最新5条异常订单日志列表
export function listCount(query) {
  return request({
    url: '/order/order-fail-log/list-count',
    method: 'get',
    params: query
  })
}
