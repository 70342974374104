import request from '@/utils/request'

// 查询结算申请列表
export function listOrderSettleRecord(query) {
  return request({
    url: '/travel/settlement/list',
    method: 'get',
    params: query
  })
}

// 生成结算单号
export function getOrderSettleNo() {
  return request({
    url: '/travel/settlement/settle-no',
    method: 'get',
  })
}

// 查询结算申请详细
export function getOrderSettleRecord(orderSettleNo) {
  return request({
    url: '/travel/settlement/' + orderSettleNo,
    method: 'get'
  })
}

// 催办审核
export function hastenWorkSms(id) {
  return request({
    url: '/travel/settlement/hasten-work',
    method: 'post',
    data: {travelOrderSettleRecordId: id}
  })
}

// 新增结算申请
export function addOrderSettleRecord(data) {
  return request({
    url: '/travel/settlement/add',
    method: 'post',
    data: data
  })
}

// 修改结算申请
export function editOrderSettleRecord(data) {
  return request({
    url: '/travel/settlement/edit',
    method: 'post',
    data: data
  })
}

// 撤回结算申请
export function revocationSettle(data) {
  return request({
    url: '/travel/settlement/revocation',
    method: 'post',
    data: data
  })
}

// 审核操作
export function auditSettle(data) {
  return request({
    url: '/travel/settlement/audit-settle',
    method: 'post',
    data: data
  })
}

// 上传凭证
export function settleUpload(data) {
  return request({
    url: '/travel/settlement/settle-upload',
    method: 'post',
    data: data
  })
}

// 加载旅行设订单
export function getTravelOrderList(data) {
  return request({
    url: '/travel/settlement/travel-orders',
    method: 'post',
    data: data
  })
}

// 加载旅行设订单
export function auditProcessCount() {
  return request({
    url: '/travel/settlement/auditProcessCount',
    method: 'get'
  })
}
